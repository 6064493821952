import React, {useState, useEffect, useContext, createRef} from "react";
import {Card, Row, Col, Spinner, Container, Button, Modal, Form, CardDeck, Toast} from "react-bootstrap";
import {useTranslation} from "react-i18next";

const Terms = ({firebase, database}) => {

    const {t, i18n} = useTranslation('common');
    return <>
        <Container className="my-5">
            <h1 className="display-4">{t('pages.agb.pagetitle')}</h1>
            {[...Array(15)].map((x, i) => {
                return (<>
                    <h5>{t(`pages.agb.${i+1}.title`)}</h5>
                    <p>{t(`pages.agb.${i+1}.content`)}
                        {i+1 === 2 && (
                            <p>
                                Softwarebuddies GmbH<br/>
                                Leopoldstr. 2-8<br/>
                                32051 Herford<br/>
                                {t('pages.agb.germany')}
                            </p>
                        )}
                    </p>
                </>)
            })
            }
        </Container>
    </>
}

export default Terms;