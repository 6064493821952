import React, { useState, useEffect, useContext } from 'react';
import { withAuthorization, withAuthentication } from '../Session';
import { compose } from 'recompose';
import {Card, Row, Col, Spinner, Table, Badge, Container, InputGroup, FormControl, Button} from 'react-bootstrap';
import { withDatabase } from '../Database';
import { LinkContainer } from 'react-router-bootstrap';
import * as ROUTES from '../../constants/routes';
import LocalizedTime from "../Common/LocalizedTime";
import { useTranslation } from "react-i18next";
import { LocalizedPrice } from "../Common/LocalizedPrice";
import _ from "lodash";

const Offers = ({ firebase, database }) => {
	const [users, setUsers] = useState(database.users);
	const [offers, setOffers] = useState(database.offers);
	const [sellRequests, setSellRequests] = useState(database.sellRequests);
	const [loading, setLoading] = useState(false);
	const { t, i18n } = useTranslation('common');
	const [searchQuery, setSearchQuery] = useState('');

	useEffect(
		() => {
			setOffers(database.offers);
		},
		[database.offers]
	);

	useEffect(() => {
		if(searchQuery === ''){
			setOffers(database.offers);
		}
		else {
			const filteredOffers = _.filter(database.offers, offer => {
				const user = _.find(database.users, {'uid': offer.uid});
				let lowercase = searchQuery.toLowerCase();
				let match = false;
				if(user && user.company){
					match |= user.company.toLowerCase().includes(lowercase);
				}
				if(user && user.email){
					match |= user.email.toLowerCase().includes(lowercase);
				}
				if(offer && offer.offer && offer.offer.easybillId){
					match |= offer.offer.easybillId.toLowerCase().includes(lowercase);
				}
				if(offer && offer.cartItems){
					match |= _.find(offer.cartItems, cartItem => {
						return cartItem.productName.toLowerCase().includes(lowercase);
					}) ? true : false;
				}
				return match;
			})
			setOffers(filteredOffers);
		}
	}, [searchQuery])
	return (
		<Container className="my-5">
			<Row>
				<Col xs={12}>
					<InputGroup className="mb-3">
						<FormControl
							placeholder={t('admin.offers.searchoffers')}
							aria-label={t('admin.offers.searchoffers')}
							value={searchQuery}
							onChange={(event) => setSearchQuery(event.target.value)}
						/>
					</InputGroup>
				</Col>
				{offers.sort(({ createdAt: previous }, { createdAt: current }) => current - previous).map((offer, key) => {
					return (
						<Col md={12} className="mt-3" key={key}>
							<Card key={key} className={(offer.status === 'Pending' || offer.status === "Ordered" || offer.status === "Confirmed") ? "action-required" : ""}>
								<Card.Header>
									{offer.offer && <>
										Easybill: {offer.offer.easybillId}
									</>}
								</Card.Header>
								<Card.Body>
									<Row>
										{offer.paymentStatus && (
											<Col xs={12} className="mb-3">
												<div className="text-success">
													<i class="fa fa-check"></i> Marked as paid
												</div>
											</Col>
										)}
										{!offer.paymentStatus && offer.status !== "Pending" && offer.status !== "Available" && offer.status !== "Declined" && (
											<Col xs={12} className="mb-3">
												<div className="text-danger">
													<i class="fa fa-times"></i> Outstanding payment
												</div>
											</Col>
										)}

										<Col xs={12} md={4}>
											<Card.Title>
												{users.filter((user) => user.uid === offer.uid).map((actualUser, index) => (
													<div key={index}>
														{actualUser.company}
														<br />
														<small>{actualUser.email}</small>
													</div>
												))}

												{offer.uid === '' && offer.userMail && (
													<div>
														<small>User not registered</small><br />
														<small>{offer.userMail}</small>
													</div>
												)}

												{offer.status == 'Pending' && <Badge variant="warning">Pending</Badge>}
												{offer.status == 'Available' && <Badge variant="primary">Available</Badge>}
												{offer.status == 'Completed' && <Badge variant="success">Completed</Badge>}
												{offer.status == 'Declined' && <Badge variant="danger">Declined</Badge>}
												{offer.status == 'Ordered' && <Badge variant="info">Ordered</Badge>}
												{offer.status == 'Confirmed' && <Badge variant="secondary">Ordered</Badge>}
											</Card.Title>
											<Card.Subtitle>
												<small>{offer.createdAt.toDate().toLocaleString()}</small>
											</Card.Subtitle>
										</Col>
										<Col xs={12} md={8}>
											<Row>
												<Col xs={2} className="text-bold small text-left">{t('cart.thproductQuantity')}</Col>
												<Col xs={6} className="text-bold small">{t('cart.thproductName')}</Col>
												<Col xs={2} className="text-bold small text-right">{t('cart.thsinglePrice')}</Col>
												<Col xs={2} className="text-bold small text-right">{t('cart.thprice')}</Col>
												{offer.cartItems.map(item => {
													return <><Col xs={2} className="text-left">
														{item.productQuantity}</Col>
														<Col xs={6}>{item.productName}</Col>
														<Col className="text-right" xs={2}><LocalizedPrice priceString={(item.price).toString()} /></Col>
														<Col className="text-right" xs={2}><LocalizedPrice priceString={(item.price * item.productQuantity).toString()} /></Col>

													</>
												})}
												<Col xs={2} className="text-bold small"></Col>
												<Col xs={2} className="text-bold small"></Col>
												<Col xs={6} className="text-bold text-right">{t('cart.overall')}:</Col>
												<Col xs={2} className="text-bold text-right"><LocalizedPrice priceString={offer.overallCartPrice.toString()}></LocalizedPrice></Col>
											</Row>
										</Col>
									</Row>
									{offer.comment && (
										<Row className="mt-2">
											<Col md={6}>Comments:</Col>
											<Col md={6}>{offer.comment}</Col>
										</Row>
									)}
									{offer.declineData && (
										<Row className="mt-2">
											<Col xs={12}><h4>Decline Information</h4></Col>
											<Col>
												<ul>
													{offer.declineData.reason1 && (<li>{t('myoffers.reason1')}</li>)}
													{offer.declineData.reason2 && (<li>{t('myoffers.reason2')}</li>)}
													{offer.declineData.reason3 && (<li>{t('myoffers.reason3')}</li>)}
													{offer.declineData.offeragain && (<li>{t('myoffers.yes')}</li>)}
													{offer.declineData.comment && (<li>{offer.declineData.comment}</li>)}
												</ul>
											</Col>
										</Row>
									)}


								</Card.Body>
								<Card.Footer>
									<LinkContainer to={`${ROUTES.ADMIN_OFFERS}/${offer.offerId}`}>
										<Card.Link>Manage</Card.Link>
									</LinkContainer>

								</Card.Footer>
							</Card>
						</Col>
					);
				})}
			</Row>
		</Container>
	);
};

const condition = (authUser) => authUser && !!authUser.isAdmin == true;

const OffersPage = compose(withAuthorization(condition), withDatabase)(Offers);

export default OffersPage;
