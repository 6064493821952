export const LANDING = "/";
export const SIGN_UP = "/signup";
export const SIGN_IN = "/signin";
export const HOME = "/home";
export const ACCOUNT = "/account";
export const ADMIN = "/admin";
export const PASSWORD_FORGET = "/pw-forget";
export const PASSWORD_RESET = "/pw-reset"

export const USERS = "/users";
export const USER = "/users/:id";
export const OFFERS = "/offers";
export const OFFER = "/offers/:id";
export const SELLREQUESTS = "/sellRequests";
export const SELLREQUEST = "/sellRequests/:id";
export const RESTRICTED = "/restricted";

export const ADMIN_USERS = "/admin/users";
export const ADMIN_USER = "/admin/users/:id";
export const ADMIN_OFFERS = "/admin/offers";
export const ADMIN_MYUSERS = "/admin/myusers";
export const ADMIN_OFFER = "/admin/offers/:offerId";
export const ADMIN_ORDERS = "/admin/orders";
export const ADMIN_ORDER = "/admin/orders/:id";
export const ADMIN_PRODUCTS = "/admin/products";
export const ADMIN_PRODUCT = "/admin/products/:id";
export const MY_OFFERS = "/myoffers";
export const MY_OFFER = "/myoffers/:id";
export const MY_ORDERS = "/myorders";
export const MY_ORDER = "/myorders/:id";


export const ADD_PRODUCT = "/addProduct/:id"


export const TERMS = "/terms";
export const PRIVACY = "/privacy";
export const IMPRINT = "/imprint";
export const CANCELLATION = "/cancellation";


