import React from "react";
import { Alert } from "react-bootstrap";

const NoAdminWarning = () => {
  // <Alert variant="warning">Sorry, you haven no rights here.</Alert>
  return (
    <Alert variant="warning" className="my-3">
      You have no rights here.
    </Alert>
  );
};

export default NoAdminWarning;
