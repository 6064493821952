import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.auth = app.auth();
    this.db = app.firestore();
    this.storage = app.storage();

    this.usersCollection = this.db.collection("users");
    this.adminsCollection = this.db.collection("admins");
    this.offersCollection = this.db.collection("offers");
    this.sellRequestsCollection = this.db.collection("sellRequests");
    this.productsCollection = this.db.collection("products");
    this.categoriesCollection = this.db.collection("categories");
    this.passwordResetsCollection = this.db.collection("passwordResets");
    this.twofactorsCollection = this.db.collection("twofactors");

    this.intermediateTime = app.firestore.Timestamp.now();
    this.serverTimeStamp = app.firestore.FieldValue.serverTimestamp();
  }



  /* Session Management */
  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doCreateUserWithEmailAndPassword = (email, password, t) => {
    return this.auth.createUserWithEmailAndPassword(email, password);
  }
  doSignOut = () => {
    this.auth.signOut();
  };
  doCreatePasswordResetRequest = (email) => {
    return this.passwordResetsCollection.add({email, createdAt: this.serverTimeStamp, sent: null})
  }

  /* 2FA Management */
  doCreateTwofactorRequest = (offerId) => {
    return this.twofactorsCollection.add({offerId, createdAt: this.serverTimeStamp, sent: null});
  }

  /* Product Management */
  doCreateCategory = (data) => {
    return this.categoriesCollection.add({ ...data, createdAt: this.serverTimeStamp });
  }
  doDeleteCategory = (categoryId) => {
    this.category(categoryId).then(doc => {
      let categoryName = doc.data().categoryName;
      let batch = this.db.batch();
      this.productsCollection.where("categoryName", "==", categoryName).get().then(qs => {
        qs.forEach(doc => {
          batch.update(doc.ref, { "categoryName": '' });
        });
        batch.commit().then(() => {
          this.categoriesCollection.doc(categoryId).delete();
        })
      })

    })
  }
  doUpdateCategory = (categoryId, data) => {
    this.category(categoryId).then(doc => {
      let categoryName = doc.data().categoryName;
      /* iterate all products and update categoryName */
      let batch = this.db.batch();
      this.productsCollection.where("categoryName", "==", categoryName).get().then(qs => {
        qs.forEach(doc => {
          batch.update(doc.ref, { "categoryName": data.categoryName });
        });
        batch.commit().then(() => {
          this.categoriesCollection.doc(categoryId).update({ ...data, updatedAt: this.serverTimeStamp });
        })
      })
    })
  }
  doCreateProduct = (data) => {
    return this.productsCollection.add({ ...data, createdAt: this.serverTimeStamp });
  }
  doDeleteProduct = (productId) => {
    this.productsCollection.doc(productId).delete();
  }
  doUpdateProduct = (productId, data) => {
    this.productsCollection.doc(productId).update({ ...data, updatedAt: this.serverTimeStamp });
  }

  // Offer Management
  doCreateOffer = (data) => {
    this.offersCollection.add({ ...data, createdAt: this.serverTimeStamp });
  }

  doCreateOrder = (offerId, data) => {
    this.offersCollection.doc(offerId).update({ ...data });
  }

  doUpdateOffer = (offerId, data) => {
    this.offersCollection.doc(offerId).update({ ...data });
  }

  // User Management
  doEnhanceUser = (uid, data) => {
    this.usersCollection.doc(uid).set({ ...data, createdAt: this.serverTimeStamp });
    // Add all offers
    this.offersCollection.where("userMail", "==", data.email).get().then(querySnapshot => {
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        this.doUpdateOffer(doc.id, { uid });
      });

    })
  }
  doUpdateUser = (uid, data) => {
    return this.usersCollection.doc(uid).update({ ...data, updatedAt: this.serverTimeStamp });
  }
  doGetCurrentUser = (uid) => {
    return this.usersCollection.doc(uid).get();
  }

  doGetCredential = (email, password) => {
    return app.auth.EmailAuthProvider.credential(email, password);
  }
  doUpdatePassword = (password) => {
    return app.auth().currentUser.updatePassword(password);
  }
  doSendPasswordResetEmail = (email) => {
    this.auth.useDeviceLanguage();
    return this.auth.sendPasswordResetEmail(email);
  }

  // File Management
  doFetchFileUrl = (fileName, offerId) => {
    const storageRef = this.storage.ref();
    const offerStorage = storageRef.child(offerId);
    const fileRef = offerStorage.child(fileName);
    return fileRef.getDownloadURL();
  }


  //* API */
  users = () => this.usersCollection.get();
  user = (uid) => this.usersCollection.doc(uid).get();
  admins = () => this.adminsCollection.get();
  admin = (uid) => this.adminsCollection.doc(uid).get();
  offers = () => this.offersCollection.get();
  offer = (offerId) => this.offersCollection.doc(offerId).get();
  sellRequests = () => this.sellRequestsCollection.get();
  sellRequest = (sellRequestId) =>
    this.sellRequestsCollection.doc(sellRequestId).get();
  products = () => this.productsCollection.get();
  product = (productId) => this.productsCollection.doc(productId).get();
  categories = () => this.categoriesCollection.get();
  category = (categoryId) => this.categoriesCollection.doc(categoryId).get();

  auth = () => this.auth();

  // Middleware
  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        this.admin(authUser.uid).then((doc) => {
          if (doc.exists) {
            authUser = {
              isAdmin: true,
              ...authUser,
            };
          }
          next(authUser);
        });
      } else {
        fallback();
      }
    });

  getUsersArray = async () => {
    this.users().then((querySnapshot) => {
      const usersArray = [];
      querySnapshot.forEach((doc) => {
        usersArray.push(doc.data());
      });
      return usersArray;
    });
  };
}

export default Firebase;
