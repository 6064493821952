
import React, { useState, useEffect, useContext } from "react";
import { withAuthentication, AuthUserContext } from '../Session';
import { compose } from "recompose";
import { Card, Row, Col, Spinner, Container, Button, Modal, Form, CardDeck, Toast, Badge, Accordion } from "react-bootstrap";
import { withDatabase } from "../Database";
import { useTranslation } from "react-i18next";
import { LocalizedPrice } from "../Common/LocalizedPrice";
import { CartContext } from "../App";
import LocalizedTime, { getDayWithZeroSeconds } from "../Common/LocalizedTime";
import OtpInput from 'react-otp-input';
import SendMail from "../Common/SendMail";

const MyOffers = ({ database, firebase }) => {
    const { t, i18n } = useTranslation('common');
    const authUser = useContext(AuthUserContext);
    const [fileLoaders, setFileLoaders] = useState({});

    const [offerList, setOfferList] = useState([]);
    const [validAccept, setValidAccept] = useState(false);
    const [otp, setOtp] = useState('');
    const [faCode, setFaCode] = useState('');
    const [currentOfferId, setCurrentOfferId] = useState('');
    const [declineData, setDeclineData] = useState({
        reason1: '',
        reason2: '',
        reason3: '',
        comment: '',
        offeragain: ''
    })
    const cartContext = useContext(CartContext);
    const [showToast, setShowToast] = useState(false);
    const toggleToast = () => setShowToast(!showToast);

    const handleOfferPdf = (fileName, offerId) => {
        setFileLoaders(prevState => ({
            ...prevState,
            [offerId]: true
        }));
        firebase.doFetchFileUrl(fileName, offerId).then(url => {
            setFileLoaders(prevState => ({
                ...prevState,
                [offerId]: false
            }));
            window.open(url, '_blank', 'noopener,noreferrer');
        }).catch(err => {
            console.log(err);
        })
    }

    useEffect(() => {
        if (authUser) {
            const actualOffers = database.offers.filter(offer => offer.uid === authUser.uid).sort(({ createdAt: previous }, { createdAt: current }) => current - previous);
            const realOffers = actualOffers.filter(offer => offer.status !== "Confirmed" && offer.status !== "Completed" && offer.status !== "Ordered");
            setOfferList(realOffers);
            // Populate offers with files
            localStorage.setItem('myOffers', JSON.stringify(realOffers));
        }
    }, [database.offers, authUser]);

    useEffect(() => {
        const currentOffer = (database.offers.filter(offer => offer.offerId === currentOfferId))[0];
        if(currentOffer){
            setFaCode(currentOffer.twofactor);
        }
    }, [database.offers, currentOfferId])

    useEffect(() => {
        const localMyOffers = JSON.parse(localStorage.getItem('myOffers'));
        if (localMyOffers) {
            const realOffers = localMyOffers.filter(offer => offer.status !== "Confirmed" && offer.status !== "Completed" && offer.status !== "Ordered");
            setOfferList(realOffers);
        }
        setOtp('');

    }, []);



    /* Modal setters */
    const [showAcceptOfferModal, setShowAcceptOfferModal] = useState(false);
    const handleCloseAcceptOfferModal = () => setShowAcceptOfferModal(false);
    const handleShowAcceptOfferModal = (offerId) => {
        setShowAcceptOfferModal(true);
        // firebase.doCreateTwofactorRequest(offerId);
        // const currentOffer = (database.offers.filter(offer => offer.offerId === offerId))[0];
        // if(currentOffer){
        //     setFaCode(currentOffer.twofactor);
        // }
        /* Ported to Notifier
        SendMail({ subject: t('mail.2fa.subject'), salutation: t('mail.2fa.salutation'), message: t('mail.2fa.message') + ' ' + random2fa.toString(), email: authUser.email });
         */
    }
    const [showDeclineModal, setShowDeclineModal] = useState(false);
    const handleCloseDeclineModal = () => {
        setShowDeclineModal(false);
        setDeclineData({
            reason1: '',
            reason2: '',
            reason3: '',
            comment: '',
            offeragain: ''
        })
    }
    const handleShowDeclineModal = () => {
        setShowDeclineModal(true);
        // const random2fa = (Math.floor(100000 + Math.random() * 900000));
        // setFaCode(random2fa);
        // SendMail({ subject: t('mail.2fa.subject'), salutation: t('mail.2fa.salutation'), message: t('mail.2fa.message') + ' ' + random2fa.toString(), email: authUser.email });
    }

    const doCreateOrder = () => {
        firebase.doCreateOrder(currentOfferId, {
            order: {
                createdAt: firebase.serverTimeStamp
            },
            status: "Ordered",
            notifications: {
                orderCreated: {
                    sent: null
                }
            }
        });
        /* Ported to Notifier
        SendMail({ subject: t('mail.order.subject'), salutation: t('mail.order.salutation'), message: t('mail.order.message'), email: authUser.email });
        */
    }

    const doDecline = () => {
        firebase.doUpdateOffer(currentOfferId, {
            declineData,
            status: "Declined"
        })
    }

    const doAddItemsFromOfferToCart = (offerId) => {
        const oldOffer = (database.offers.filter(offer => offer.offerId === offerId))[0];
        if (oldOffer) {
            oldOffer.cartItems.forEach(cartItem => {
                cartContext.addToCart(cartItem);
            })
        }
        setShowToast(true);
    }

    return <>
        {/* Accept offer modal */}
        <Modal show={showAcceptOfferModal} onHide={handleCloseAcceptOfferModal}>
            <Modal.Header closeButton>
                <Modal.Title>{t('myoffers.acceptoffer')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {t('myoffers.areyousure')} "{t('myoffers.order')}".
            </Modal.Body>
            <Modal.Footer>
                <Button variant={"light"} className="light" onClick={handleCloseAcceptOfferModal}>
                    {t('global.close')}
                </Button>
                <Button className="bg-secondary" onClick={() => { doCreateOrder(); handleCloseAcceptOfferModal(); }}>
                    {t('myoffers.order')}
                </Button>
            </Modal.Footer>
        </Modal>
        {/* Deline offer modal */}
        <Modal show={showDeclineModal} onHide={handleCloseDeclineModal}>
            <Modal.Header closeButton>
                <Modal.Title>{t('myoffers.decline')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {t('myoffers.feedback')}
                <p className="my-3">
                    <Form.Check
                        type={'checkbox'}
                        id={'reason1'}
                        label={t('myoffers.reason1')}
                        onChange={(event) => { setDeclineData({ ...declineData, reason1: event.target.checked }) }}
                    />
                    <Form.Check
                        type={'checkbox'}
                        id={'reason2'}
                        label={t('myoffers.reason2')}
                        onChange={(event) => { setDeclineData({ ...declineData, reason2: event.target.checked }) }}
                    />
                    <Form.Check
                        type={'checkbox'}
                        id={'reason3'}
                        label={t('myoffers.reason3')}
                        onChange={(event) => { setDeclineData({ ...declineData, reason3: event.target.checked }) }}

                    />
                    <Form.Label>{t('myoffers.comment')}</Form.Label>
                    <Form.Control as="textarea" rows={3} maxLength="256"
                        onChange={(event) => { setDeclineData({ ...declineData, comment: event.target.value }) }}
                    />
                </p>
                <p className="my-3">
                    {t('myoffers.offeragain')}
                    <Form.Check
                        type={'checkbox'}
                        id={'offeragain'}
                        label={t('myoffers.yes')}
                        onChange={(event) => { setDeclineData({ ...declineData, offeragain: event.target.checked }) }}

                    />
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button className="bg-secondary" onClick={handleCloseDeclineModal}>
                    {t('global.close')}
                </Button>
                <Button className="bg-danger" onClick={() => { doDecline(); handleCloseDeclineModal(); }}>
                    {t('myoffers.decline')}
                </Button>
            </Modal.Footer>
        </Modal>

        <Container className="my-5">
            <h1 className="h2">{t('navigation.myoffers')}</h1>
            <Row>
                {offerList.length === 0 &&
                    <Col xs={12} className="mb-3">
                        <p>{t('myoffers.nooffers')}</p>
                    </Col>}
                <Accordion defaultActiveKey="0" className="w-100">
                    {offerList.length !== 0 && offerList.map((offer, index) => {
                        return <Col xs={12} md={12} className="my-3">
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey={`${index}`} className="d-flex flex-direction-row justify-content-between offerButton w-100 p-0">
                                        <div className="text-left">
                                            <h2>{t('myoffers.onlyoffer')} {''} {offer.offer && offer.offer.easybillId}</h2>
                                            <span className="small">
                                                {t('myoffers.requestedat')}{' '}
                                                <LocalizedTime seconds={offer.createdAt.seconds * 1000} />
                                            </span>
                                        </div>
                                        <div>
                                            {offer.status === 'Pending' && <Badge variant="warning">{t('global.status.pending')}</Badge>}
                                            {offer.status === 'Available' && <Badge variant="primary">{t('global.status.available')}</Badge>}
                                            {offer.status === 'Completed' && <Badge variant="success">{t('global.status.completed')}</Badge>}
                                            {offer.status === 'Declined' && <Badge variant="danger">{t('global.status.declined')}</Badge>}
                                            {offer.status === 'Ordered' && <Badge variant="info">{t('global.status.ordered')}</Badge>}
                                            {offer.status === 'Confirmed' && <Badge variant="secondary">{t('global.status.confirmed')}</Badge>}
                                        </div>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey={`${index}`}>
                                    <>
                                        <Card.Body>
                                            <Card.Title>{t('myoffers.overviewyourrequest')}{' '}</Card.Title>
                                            <Card.Text>
                                                <Row>
                                                    <Col xs={2} className="text-bold small text-left">{t('cart.thproductQuantity')}</Col>
                                                    <Col xs={6} className="text-bold small">{t('cart.thproductName')}</Col>
                                                    <Col xs={2} className="text-bold small text-right">{t('cart.thsinglePrice')}</Col>
                                                    <Col xs={2} className="text-bold small text-right">{t('cart.thprice')}</Col>
                                                    {offer.cartItems.map(item => {
                                                        return <><Col xs={2} className="text-left">
                                                            {item.productQuantity}</Col>
                                                            <Col xs={6}>{item.productName}</Col>
                                                            <Col className="text-right" xs={2}><LocalizedPrice priceString={(item.price).toString()} /></Col>
                                                            <Col className="text-right" xs={2}><LocalizedPrice priceString={(item.price * item.productQuantity).toString()} /></Col>

                                                        </>
                                                    })}
                                                    <Col xs={2} className="text-bold small"></Col>
                                                    <Col xs={2} className="text-bold small"></Col>
                                                    <Col xs={6} className="text-bold text-right">{t('cart.overall')}:</Col>
                                                    <Col xs={2} className="text-bold text-right"><LocalizedPrice priceString={offer.overallCartPrice.toString()}></LocalizedPrice></Col>
                                                </Row>
                                                <Badge onClick={() => { setCurrentOfferId(offer.offerId); doAddItemsFromOfferToCart(offer.offerId); }} variant="info" className="repeatOfferBadge"><i className="fa fa-redo"></i> {t('myoffers.repeatoffer')}</Badge>
                                            </Card.Text>
                                            {offer.status !== "Pending" && (
                                                <Card>
                                                    <Card.Body>
                                                        <Card.Title>
                                                            {t('myoffers.ouroffer')}
                                                        </Card.Title>
                                                        <Card.Text>
                                                            <div>
                                                                {t('myoffers.netprice')}: <span className="font-weight-bold"><LocalizedPrice priceString={(offer.offer.netPrice).toString()} /></span>
                                                            </div>
                                                            <div>
                                                                {t('myoffers.validuntil')}: <span className="font-weight-bold"><LocalizedTime reduceToDay={true} seconds={offer.offer.validUntil.seconds * 1000}></LocalizedTime></span>
                                                            </div>
                                                            <div className="text-primary show-cursor d-inline" onClick={() => handleOfferPdf(offer.offer.fileName, offer.offerId)}>
                                                                {t('myoffers.offerpdf')}{' '}(PDF)
                                                    {fileLoaders[offer.offerId] && <Spinner animation="grow" size="sm" />}
                                                            </div>
                                                        </Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            )}
                                        </Card.Body>

                                        <Card.Footer>
                                            {offer.status === "Available" && (
                                                <div className="d-flex justify-content-between flex-direction-row">
                                                    <Button className={"bg-secondary"} onClick={() => { handleShowAcceptOfferModal(offer.offerId); setCurrentOfferId(offer.offerId) }} disabled={(offer.status === "Available" && (parseInt(new Date(Date.now()).getTime()) - parseInt(getDayWithZeroSeconds(offer.offer.validUntil.seconds * 1000 + 86400000).getTime()) >= 0))}>
                                                        <i className={"fa fa-check"}></i>{' '}
                                                        {t('myoffers.order')}
                                                    </Button>
                                                    <Button variant="light" onClick={() => {
                                                        handleShowDeclineModal();
                                                        setCurrentOfferId(offer.offerId);
                                                    }} disabled={offer.status !== "Available"}>
                                                        <i className={"fa fa-times"}></i>{' '}
                                                        {t('myoffers.decline')}
                                                    </Button>
                                                </div>
                                            )}

                                        </Card.Footer>
                                    </>
                                </Accordion.Collapse>

                            </Card>
                        </Col>
                    })}
                </Accordion>
            </Row>
            <Toast show={showToast} onClose={() => toggleToast()} className="cartToast" delay={3000} autohide>
                <Toast.Header className="text-secondary">
                    <strong className="mr-auto">{t('cart.cart')}</strong>
                </Toast.Header>
                <Toast.Body>{t('myoffers.itemstocart')}</Toast.Body>
            </Toast>
        </Container>
    </>
}

const MyOffersPage = compose(withAuthentication, withDatabase)(MyOffers);

export default MyOffersPage;