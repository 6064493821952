
import React, { useState, useEffect, useContext, createRef } from "react";
import { withAuthentication, AuthUserContext, withAuthorization } from '../Session';
import { compose } from "recompose";
import { Card, Row, Col, Spinner, Container, Button, Modal, Form, CardDeck, Toast, Badge, Accordion, Alert } from "react-bootstrap";
import { withDatabase } from "../Database";
import { useTranslation } from "react-i18next";

import { LocalizedPrice } from "../Common/LocalizedPrice";
import LocalizedTime, { getDayWithZeroSeconds } from "../Common/LocalizedTime";
import OtpInput from 'react-otp-input';

const INITIAL_USER = {
    company: '',
    name: '',
    street: '',
    city: '',
    country: '',
    vat: '',
    email: '',
    zip: '',
}

const INITIAL_PW = {
    password1: '',
    password2: '',
    currentPassword: '',
}
const MyAccount = ({ database, firebase }) => {
    const { t, i18n } = useTranslation('common');
    const authUser = useContext(AuthUserContext);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");
    const [company, setCompany] = useState("");
    const [postal, setPostal] = useState("");
    const [name, setName] = useState("");
    const [street, setStreet] = useState("");
    const [city, setCity] = useState("");
    const [country, setCountry] = useState("");
    const [vat, setVat] = useState("");
    const [checked, setChecked] = useState(false);
    const [valid, setValid] = useState(false);
    const [error, setError] = useState("");
    const [errorCount, setErrorCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [user, setUser] = useState(INITIAL_USER);
    const [loadingUser, setLoadingUser] = useState(true);
    const [saveSuccess, setSaveSuccess] = useState(false);
    const [passwordObj, setPasswordObj] = useState(INITIAL_PW);
    const [passwordSuccess, setPasswordSuccess] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [passwordValid, setPasswordValid] = useState(false);
    const [reauthenticate, setReauthenticate] = useState(false);
    const [reauthLoading, setReauthLoading] = useState(false);

    const doUpdateUser = () => {
        setLoading(true);
        firebase.doUpdateUser(authUser.uid, user);
        setSaveSuccess(true);
        setLoading(false);
    }

    const doChangePassword = () => {
        firebase.doUpdatePassword(passwordObj.password1).then((res) => {
            setPasswordSuccess(true);
        }).catch((err) => {
            setPasswordError(true);
        });
    }

    const doReauthenticate = () => {
        setReauthLoading(true);

        const credential = firebase.doGetCredential(
            authUser.email,
            passwordObj.currentPassword
        );
        authUser.reauthenticateWithCredential(credential).then(() => {
            setReauthenticate(true);
            setReauthLoading(false);
        }).catch(err => {
            setReauthenticate(false);
            setReauthLoading(false);
            console.log(err);
        })
    }

    useEffect(() => {
        setLoadingUser(true);
        if (authUser) {
            firebase.doGetCurrentUser(authUser.uid).then(doc => {
                setUser(doc.data());
                setLoadingUser(false);
            })
        }

    }, [authUser])

    useEffect(() => {
        setValid(
            user.email.length !== 0 &&
            user.email.includes("@") &&
            user.name.length > 2 && user.company.length > 2 && user.zip.length > 2 && user.city.length > 2 && user.street.length > 2 && user.country.length > 2
        );
    }, [user]);

    useEffect(() => {
        setPasswordValid(
            passwordObj.currentPassword !== "" &&
            passwordObj.password1 === passwordObj.password2 && passwordObj.password1 !== "" && reauthenticate === true
        );
    }, [passwordObj, reauthenticate])

    return <>

        <Container className="my-5">
            {loadingUser && <Spinner animation="grow" className="text-center" />}
            {!loadingUser && (
                <>
                    <Row><Col>
                        <h1 className="h2">{t('account.title')} </h1>

                    </Col>
                    </Row>
                    <Row className="box-border-left shadow p-3 bg-light my-3">
                        <Col xs={12} md={6}>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>{t('signup.email')}</Form.Label>
                                <Form.Control
                                    type="email"
                                    value={user.email}
                                    maxLength="100"
                                    autoComplete="email"
                                    disabled
                                />
                            </Form.Group>
                            <Form.Group controlId="formBasicName">
                                <Form.Label>{t('signup.name')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t('signup.name')}
                                    value={user.name}
                                    onChange={(event) => setUser({ ...user, name: event.target.value })}
                                    maxLength="100"
                                    autoComplete="name"
                                />
                            </Form.Group>
                            <Form.Group controlId="formBasicCompany">
                                <Form.Label>{t('signup.company')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t('signup.company')}
                                    value={user.company}
                                    onChange={(event) => setUser({ ...user, company: event.target.value })}
                                    maxLength="100"
                                    autoComplete="organization"
                                />
                            </Form.Group>
                            <Form.Group controlId="formBasicStreet">
                                <Form.Label>{t('signup.street')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t('signup.street')}
                                    value={user.street}
                                    onChange={(event) => setUser({ ...user, street: event.target.value })}
                                    maxLength="100"
                                    autoComplete="street-address"
                                />
                            </Form.Group>

                        </Col>
                        <Col xs={12} md={6}>
                            <Form.Group controlId="formBasicPostal">
                                <Form.Label>{t('signup.postal')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t('signup.postal')}
                                    value={user.zip}
                                    onChange={(event) => setUser({ ...user, zip: event.target.value })}
                                    maxLength="100"
                                    autoComplete="postal-code"
                                />
                            </Form.Group>
                            <Form.Group controlId="formBasicCity">
                                <Form.Label>{t('signup.city')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t('signup.city')}
                                    value={user.city}
                                    onChange={(event) => setUser({ ...user, city: event.target.value })}
                                    maxLength="100"
                                    autoComplete="address-level2"
                                />
                            </Form.Group>
                            <Form.Group controlId="formBasicPostal">
                                <Form.Label>{t('signup.country')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t('signup.country')}
                                    value={user.country}
                                    onChange={(event) => setUser({ ...user, country: event.target.value })}
                                    maxLength="100"
                                    autoComplete="country"
                                />
                            </Form.Group>
                            <Form.Group controlId="formBasicVat">
                                <Form.Label>{t('signup.uid')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={user.vat}
                                    onChange={(event) => setUser({ ...user, vat: event.target.value })}
                                    maxLength="100"

                                />
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <div className="text-muted small my-3">{t('common:signup:required')}</div>
                            {!saveSuccess && (
                                <Button variant="primary" disabled={!valid || disabled} onClick={() => doUpdateUser()}>
                                    {t('account.savechanges')} {loading && <Spinner animation="grow" size="sm" />}
                                </Button>
                            )}
                            {saveSuccess && (
                                <Alert variant="success" className="my-3">
                                    {t('account.successchanges')}
                                </Alert>
                            )}

                            {error && (
                                <Alert variant="danger" className="my-3">
                                    {t('account.errorchanges')}
                                </Alert>
                            )}
                        </Col>
                    </Row>
                </>
            )}
            {!loadingUser && (
                <>
                    <Row className="mt-5 mb-3">
                        <Col>
                            <h1 className="h3">{t('account.changepassword')}</h1>
                        </Col>
                    </Row>
                    <Row className=" box-border-left shadow p-3 bg-light ">
                        <Col xs={12} md={6}>
                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>{t('account.currentpassword')}</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder={t('account.currentpassword')}
                                    value={passwordObj.currentPassword}
                                    onChange={(event) => setPasswordObj({ ...passwordObj, currentPassword: event.target.value })}
                                    maxLength="256"
                                    autoComplete="password"
                                    disabled={reauthenticate}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                            <div className="text-muted small">{t('account.reauthenticate')}</div>
                            {!reauthenticate && (
                                <Button variant="secondary" className="mx-0 small" onClick={() => doReauthenticate()}>
                                    {reauthLoading && <Spinner animation="grow" size="sm" />} {t('account.doreauthenticate')}  <i className="fa fa-redo"></i>
                                </Button>
                            )}
                        </Col>
                        <hr className="w-100"></hr>
                        <Col xs={12} md={6}>
                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>{t('account.newpassword')}</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder={t('account.newpassword')}
                                    value={passwordObj.password1}
                                    onChange={(event) => setPasswordObj({ ...passwordObj, password1: event.target.value })}
                                    maxLength="256"
                                    autoComplete="new-password"
                                    disabled={!reauthenticate}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>{t('signup.repeatpassword')}</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder={t('signup.repeatpassword')}
                                    value={passwordObj.password2}
                                    onChange={(event) => setPasswordObj({ ...passwordObj, password2: event.target.value })}
                                    maxLength="256"
                                    disabled={!reauthenticate}
                                />
                            </Form.Group>
                        </Col>

                        <Col xs={12}>
                            {!passwordSuccess && (
                                <Button variant="primary" disabled={!passwordValid || disabled} onClick={() => doChangePassword()}>
                                    {t('account.changepassword')} {loading && <Spinner animation="grow" size="sm" />}
                                </Button>
                            )}
                            {passwordSuccess && (
                                <Alert variant="success" className="my-3">
                                    {t('account.successchanges')}
                                </Alert>
                            )}

                            {passwordError && (
                                <Alert variant="danger" className="my-3">
                                    {t('account.errorchanges')}
                                </Alert>
                            )}
                        </Col>
                    </Row>
                </>
            )}


        </Container>
    </>
}

const MyAccountPage = compose(withAuthentication, withDatabase)(MyAccount);

export default MyAccountPage;