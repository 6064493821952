import React, { useState, useEffect, useContext } from "react";
import { withAuthorization, withAuthentication } from "../Session";
import { compose } from "recompose";
import { Card, Row, Col, Spinner } from "react-bootstrap";
import { withDatabase } from "../Database";
const Home = ({ firebase, database }) => {
  const [users, setUsers] = useState(database.users);
  const [offers, setOffers] = useState(database.offers);
  const [sellRequests, setSellRequests] = useState(database.sellRequests);
  const [loading, setLoading] = useState(false);

  return (
    <Row>
      <Col xs={12} md={4}>
        <Card>
          <Card.Body>
            <Card.Title>User Statistics</Card.Title>
            {!loading && (
              <Card.Text>Total Registrations: {users.length}</Card.Text>
            )}
            {loading && <Spinner animation="grow" />}
          </Card.Body>
        </Card>
      </Col>
      <Col xs={12} md={4}>
        <Card>
          <Card.Body>
            <Card.Title>Offers Statistics</Card.Title>
            {!loading && (
              <Card.Text>Total Offer Requests: {offers.length}</Card.Text>
            )}
            {loading && <Spinner animation="grow" />}
          </Card.Body>
        </Card>
      </Col>
      <Col xs={12} md={4}>
        <Card>
          <Card.Body>
            <Card.Title>Sell Requests Statistics</Card.Title>
            {!loading && (
              <Card.Text>Total Sell Requests: {sellRequests.length}</Card.Text>
            )}
            {loading && <Spinner animation="grow" />}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

const condition = (authUser) => authUser && !!authUser.isAdmin == true;

const HomePage = compose(withAuthorization(condition), withDatabase)(Home);

export default HomePage;
