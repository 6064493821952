import React, { useState, useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { Form, Button, Spinner, Alert, Container, Row, Col, Nav } from "react-bootstrap";
import { LinkContainer } from 'react-router-bootstrap';

import { withFirebase } from "../Firebase";
import { compose } from "recompose";
import { useTranslation } from "react-i18next";

import * as ROUTES from "../../constants/routes";

const SignIn = ({ firebase }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [valid, setValid] = useState(false);
  const [error, setError] = useState("");
  const [errorCount, setErrorCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [waitingTime, setWaitingTime] = useState(0);
  const history = useHistory();
  const { t, i18n } = useTranslation('common');


  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        setEmail("");
        setPassword("");
        setError("");
        setLoading(false);
        history.push(ROUTES.LANDING);
      })
      .catch((err) => {
        setErrorCount((errorCount) => errorCount + 1);
        setError(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    setValid(
      email.length !== 0 && password.length !== 0 && email.includes("@")
    );
    if (errorCount > 2) {
      setDisabled(true);
      setTimeout(() => {
        setDisabled(false);
      }, errorCount * errorCount * 1000);
      setWaitingTime(errorCount * errorCount);
    }
  }, [email, password, errorCount]);

  return (
    <div className="fullpage-background">
      <Form onSubmit={handleSubmit} className="mt-5 mb-3">
        <Container>
          <div className="row-fluid">
            <Col lg={4} md={6} className="box-border-left shadow p-5 p-sm-3 p-lg-5 bg-light">
              <h1>{t('signin.signin')}</h1>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>{t('signin.email')}</Form.Label>
                <Form.Control
                  type="email"
                  placeholder={t('signin.entermail')}
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="formBasicPassword">
                <Form.Label>{t('signin.password')}</Form.Label>
                <Form.Control
                  type="password"
                  placeholder={t('signin.password')}
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                />
              </Form.Group>
              <Button variant="primary" type="submit" disabled={!valid || disabled}>
                {t('signin.signin')} {loading && <Spinner animation="grow" size="sm" />}
              </Button>
              <div className="my-3 text-muted small">
                {t('signin.passwordreset')}
                <LinkContainer to={ROUTES.PASSWORD_FORGET}>
                  <Nav.Link className="d-inline py-0 px-1">Link</Nav.Link>
                </LinkContainer>
              </div>
              {error && (
                <Alert variant="danger" className="my-3">
                  {t('signin.error')}
                </Alert>
              )}
              {errorCount > 2 && disabled && (
                <Alert variant="warning" className="my-3">
                  {t('signin.errorcount', { waitingTime })}
                  <Spinner animation="grow" size="sm" />
                </Alert>
              )}
            </Col>
          </div>
        </Container>
      </Form>
    </div>
  );
};

const SignInPage = compose(withRouter, withFirebase)(SignIn);

export default SignInPage;
