import React, {useState, useContext, Suspense, useEffect, useRef} from 'react';
import {Link, useHistory} from 'react-router-dom';

import {Navbar, Nav, NavDropdown, Button, Container, Modal, Row, Col, InputGroup, FormControl} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faLanguage,
    faUser,
    faGlobeEurope,
    faGlobe,
    faShoppingCart,
    faChevronLeft,
    faChevronRight,
    faChevronCircleDown,
    faChevronDown,
    faToolbox,
    faTrash
} from '@fortawesome/free-solid-svg-icons'
import {useTranslation} from "react-i18next";
import {compose} from "recompose";

import Cookies from 'universal-cookie';


import * as ROUTES from '../../constants/routes';

import {withAuthentication, AuthUserContext, withAuthorization} from '../Session';
import {CartContext} from '../App';
import {LocalizedPrice} from '../Common/LocalizedPrice';
import SendMail from '../Common/SendMail';
import {withDatabase} from '../Database';


const changeLanguageAndSetCookie = (lang, i18n) => {
    const cookies = new Cookies();
    i18n.changeLanguage(lang);
    cookies.set('prefered_lang', lang, {path: '/'});
    // window.location.reload();
}

const NavAdminUi = ({t}) => {
    // const { t, i18n } = useTranslation('common');

    return (
        <NavDropdown title={t('navigation.admin')} id="account-nav-dropdown" className="border rounded shadow-sm">
            <NavDropdown.Item>
                <LinkContainer to={ROUTES.ADMIN_MYUSERS}>
                    <Nav.Link className="">
                        {t('navigation.myusers')}
                    </Nav.Link>
                </LinkContainer>
            </NavDropdown.Item>
            <NavDropdown.Divider></NavDropdown.Divider>
            <NavDropdown.Header className={"p-2"}>{t('navigation.overview')}</NavDropdown.Header>
            <NavDropdown.Item>
                <LinkContainer to={ROUTES.ADMIN_OFFERS}>
                    <Nav.Link className="">
                        {t('navigation.manageoffers')}
                    </Nav.Link>
                </LinkContainer>
            </NavDropdown.Item>
            <NavDropdown.Item>
                <LinkContainer to={ROUTES.ADMIN_USERS}>
                    <Nav.Link className="">
                        {t('navigation.managecustomers')}
                    </Nav.Link>
                </LinkContainer>
            </NavDropdown.Item>
            <NavDropdown.Item>
                <LinkContainer to={ROUTES.ADMIN_PRODUCTS}>
                    <Nav.Link className="">
                        {t('navigation.manageproducts')}
                    </Nav.Link>
                </LinkContainer>
            </NavDropdown.Item>
        </NavDropdown>
    )
}
const condition = (authUser) => authUser && !!authUser.isAdmin == true;

const NavAdmin = compose(withAuthorization(condition))(NavAdminUi);


const Navigation = ({firebase, database, isAdmin, authUser}) => {
    const cartContext = useContext(CartContext);
    const [cartItemsCount, setCartItemsCount] = useState(cartContext.cartItems.length);
    const [showCart, setShowCart] = useState(false);
    const history = useHistory();
    // const authUser = useContext(AuthUserContext);
    const {t, i18n} = useTranslation('common');
    const [userMail, setUserMail] = useState('');
    const inputRef = useRef();
    const doSignOut = () => {
        if (firebase) {
            firebase.doSignOut();
        }
        history.push(ROUTES.LANDING);
    };
    const handleShowCart = () => {
        cartContext.setShowCart(true);
    }
    const handleCloseCart = () => {
        cartContext.setShowCart(false);
    }

    const doCreateOffer = () => {
        // prepare data
        let cartItems = [...cartContext.cartItems];
        // keep also amount for legacy reasons
        cartItems.forEach(cartItem => {
            cartItem["amount"] = cartItem.productQuantity;
        })
        firebase.doCreateOffer({
            uid: authUser.uid,
            cartItems: cartItems,
            status: "Pending",
            overallCartPrice: cartContext.getOverAllCartPrice(),
            notifications: {
                offerCreatedByUser: {
                    sent: null,
                }
            }
        });
        /* Ported to Notifier
        SendMail({ subject: t('mail.offer.subject'), salutation: t('mail.offer.salutation'), message: t('mail.offer.message'), email: authUser.email });
         */
        cartContext.resetCart();
        history.push(ROUTES.MY_OFFERS);
    }
    const doCreateOfferForUser = () => {
        // prepare data
        let cartItems = [...cartContext.cartItems];
        // keep also amount for legacy reasons
        cartItems.forEach(cartItem => {
            cartItem["amount"] = cartItem.productQuantity;
        })
        let uid = '';
        let checkUser = database.users.filter(user => user.email === inputRef.current.value);
        if (checkUser && checkUser[0])
            uid = checkUser[0].uid;

        firebase.doCreateOffer({
            uid,
            userMail: inputRef.current.value,
            cartItems: cartItems,
            status: "Pending",
            overallCartPrice: cartContext.getOverAllCartPrice(),
            notifications: {
                offerCreatedForUser: {
                    sent: null,
                }
            }
        });
        // // SendMail({ subject: t('mail.adminoffer.subject'), salutation: t('mail.adminoffer.salutation'), message: t('mail.adminoffer.message'), email: userMail });
        cartContext.resetCart();
        history.push(ROUTES.ADMIN_OFFERS);

    }

    useEffect(() => {
        setCartItemsCount(cartContext.cartItems.length);
    }, [cartContext.cartItems]);


    const CartComponent = () => {
        return <Nav.Link
            className={`d-flex align-items-center linkwrapper ${cartContext.cartItems.length !== 0 && "text-secondary"}`}
            onClick={handleShowCart}>
            <FontAwesomeIcon icon={faShoppingCart} className=""></FontAwesomeIcon>
            <div className="text-muted small">({cartContext.cartItems.length})</div>
        </Nav.Link>
    }

    const CartModal = () => {
        return <Modal show={cartContext.showCart} onHide={handleCloseCart} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>{t('cart.cart')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="lead small">{t('cart.offerinformation')}</p>
                {cartContext.cartItems.length !== 0 && <Row>
                    <Col xs={4} className="text-bold small">{t('cart.thproductName')}</Col>
                    <Col xs={2} className="text-bold small text-right">{t('cart.thproductQuantity')}</Col>
                    <Col xs={2} className="text-bold small text-right">{t('cart.thsinglePrice')}</Col>
                    <Col xs={2} className="text-bold small text-right">{t('cart.thprice')}</Col>
                    <Col xs={2} className="text-bold small text-right"></Col>
                    {cartContext.cartItems.map((cartItem, index) => {
                        const priceCalculated = parseFloat(cartItem.price) * parseFloat(cartItem.productQuantity);
                        return (
                            <React.Fragment key={index}>
                                <Col className="my-2" xs={4}>{cartItem.productName}</Col>
                                <Col className="my-2 text-right" xs={2}> <InputGroup className="mb-3">
                                    <FormControl size="sm" type="number" min={1} max={9999} step={1}
                                                 placeholder={cartItem.productQuantity} key={index}
                                                 onMouseOut={(event) => {
                                                     cartContext.updateQuantityByIndex(index, event.target.value)
                                                 }}/>
                                </InputGroup>
                                </Col>
                                <Col className="my-2 text-right" xs={2}><LocalizedPrice
                                    priceString={(cartItem.price).toString()}></LocalizedPrice></Col>
                                <Col className="my-2 text-right" xs={2}><LocalizedPrice
                                    priceString={(priceCalculated.toString())}></LocalizedPrice></Col>
                                <Col className="my-2" xs={2}><FontAwesomeIcon className="show-cursor" icon={faTrash}
                                                                              title={t('cart.removeProduct')}
                                                                              onClick={() => cartContext.removeCartItemByIndex(index)}></FontAwesomeIcon></Col>
                            </React.Fragment>
                        )
                    })}
                    <hr/>
                    <Col xs={4} className="text-bold small"></Col>
                    <Col xs={2} className="text-bold small text-right"></Col>
                    <Col xs={2} className="text-bold text-right">{t('cart.overall')}:</Col>
                    <Col xs={2} className="text-bold text-right"><LocalizedPrice
                        priceString={cartContext.getOverAllCartPrice().toString()}></LocalizedPrice></Col>
                    <Col xs={2} className="text-bold small text-right"></Col>
                </Row>}
                {cartContext.cartItems.length === 0 && <p>{t('cart.noitems')}</p>}

            </Modal.Body>
            <Modal.Footer>
                <div className={"d-flex flex-row justify-content-between"}>
                    {!authUser && <Button variant={"primary"} onClick={() => {
                        handleCloseCart();
                        history.push(ROUTES.SIGN_UP);
                    }}>{t('mail.offeravailablenewuser.signup')}</Button>}
                    <div>
                        <Button variant="primary" onClick={() => {
                            handleCloseCart();
                            doCreateOffer();
                        }} disabled={!authUser || cartContext.cartItems.length === 0}>
                            {t('cart.createoffer')}
                        </Button>
                        <Button variant="light" onClick={() => {
                            cartContext.resetCart();
                            handleCloseCart();
                        }} disabled={cartContext.cartItems.length === 0}>
                            {t('cart.resetCart')}
                        </Button>
                    </div>
                </div>
                {!authUser && <p className="text-muted small w-100 text-right">{t('cart.nouser')}</p>}

            </Modal.Footer>
            {authUser && authUser.isAdmin && (
                <>
                    <Modal.Body key={"adminModal"}>
                        {t('admin.offerforuser')}<br/>
                        <FormControl
                            id="mailToUser"
                            type="text"
                            ref={inputRef}
                            // value={userMail}
                            // onChange={(event) => {
                            //     setUserMail((event.target.value));
                            // }}
                        />
                        <Button variant="light" onClick={() => {
                            handleCloseCart();
                            doCreateOfferForUser();
                        }}>
                            {t('admin.addoffer')}
                        </Button>
                    </Modal.Body>
                </>
            )}
        </Modal>
    }
    const NavBarNonAuth = () => (
        <>
            <Navbar expand="lg" className="main-nav shadow bg-white sticky-top">
                <Container>
                    <LinkContainer to={ROUTES.LANDING}>
                        <Navbar.Brand>
                            <img
                                src="/logos/Logo_Softwarebuddies_r.svg"
                                width="180"
                                className="d-inline-block"
                                alt="Softwarebuddies Logo"
                            />
                        </Navbar.Brand>
                    </LinkContainer>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                        </Nav>
                        <Nav>
                            <LinkContainer to={ROUTES.SIGN_IN}>
                                <Nav.Link>{t('navigation.signin')}</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to={ROUTES.SIGN_UP}>
                                <Nav.Link>{t('navigation.signup')}</Nav.Link>
                            </LinkContainer>
                            <CartComponent/>
                            <NavDropdown title={(i18n.language).toUpperCase()} id="language-nav-dropdown">
                                {i18n.language === 'en' && (
                                    <NavDropdown.Item
                                        onClick={() => changeLanguageAndSetCookie('de', i18n)}>DE</NavDropdown.Item>
                                )}
                                {i18n.language === 'de' && (
                                    <NavDropdown.Item
                                        onClick={() => changeLanguageAndSetCookie('en', i18n)}>EN</NavDropdown.Item>
                                )}
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <CartModal/>
        </>
    );
    const NavBarUser = () => (
        <>
            <Navbar expand="lg" className="main-nav shadow bg-white sticky-top">
                <Container>
                    <LinkContainer to={ROUTES.LANDING} exact>
                        <Navbar.Brand>
                            <img
                                src="/logos/Logo_Softwarebuddies_r.svg"
                                width="180"
                                className="d-inline-block"
                                alt="Softwarebuddies Logo"
                            />
                        </Navbar.Brand>
                    </LinkContainer>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                        </Nav>
                        <Nav>
                            <LinkContainer to={ROUTES.LANDING} exact>
                                <Nav.Link className="d-flex align-items-center linkwrapper">
                                    {t('navigation.overview')}
                                </Nav.Link>
                            </LinkContainer>
                            <LinkContainer to={ROUTES.MY_OFFERS} exact>
                                <Nav.Link className="d-flex align-items-center linkwrapper">
                                    {t('navigation.myoffers')}
                                </Nav.Link>
                            </LinkContainer>
                            <LinkContainer to={ROUTES.MY_ORDERS} exact>
                                <Nav.Link className="d-flex align-items-center linkwrapper">
                                    {t('navigation.myorders')}
                                </Nav.Link>
                            </LinkContainer>
                            <CartComponent/>
                            <NavDropdown title={<FontAwesomeIcon icon={faUser} className="mr-2"/>
                            } id="account-nav-dropdown">
                                <NavDropdown.Item>
                                    <LinkContainer to={ROUTES.ACCOUNT}>
                                        <Nav.Link className="">
                                            {t('navigation.settings')}
                                        </Nav.Link>
                                    </LinkContainer>
                                </NavDropdown.Item>
                                <NavDropdown.Item>
                                    <Nav.Link onClick={doSignOut}>
                                        {t('navigation.signout')}
                                    </Nav.Link>
                                </NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title={(i18n.language).toUpperCase()} id="language-nav-dropdown">
                                {i18n.language === 'de' && (
                                    <NavDropdown.Item onClick={() => {
                                        changeLanguageAndSetCookie('en', i18n);
                                        if (authUser && authUser.uid !== "") {
                                            firebase.doUpdateUser(authUser.uid, {preferedLang: 'en'});
                                        }
                                    }}>EN</NavDropdown.Item>
                                )}
                                {i18n.language === 'en' && (
                                    <NavDropdown.Item onClick={() => {
                                        changeLanguageAndSetCookie('de', i18n);
                                        if (authUser && authUser.uid !== "") {
                                            firebase.doUpdateUser(authUser.uid, {preferedLang: 'de'});
                                        }
                                    }}>DE</NavDropdown.Item>
                                )}
                            </NavDropdown>
                            <NavAdmin t={t}/>
                        </Nav>
                    </Navbar.Collapse>

                </Container>
            </Navbar>
            <CartModal/>
        </>
    );

    return (
        authUser ? <NavBarUser/> : <NavBarNonAuth/>);
};


export default compose(withAuthentication, withDatabase)(Navigation);
