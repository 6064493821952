import React from "react";
import { MDBDataTableV5 } from 'mdbreact';
import { useTranslation } from "react-i18next";


export const DataTable = ({ dataTable, className, entries }) => {
    const { t, i18n } = useTranslation('common');

    return <MDBDataTableV5
        searchLabel={t('global.search')}
        entriesLabel={t('global.rowsperpage')}
        infoLabel={[t('global.showing'), t('global.to'), t('global.of'), t('global.entries')]}
        noRecordsFoundLabel={t('global.norecordsfound')}
        className={className}
        hover entriesOptions={[5, 10, 20, 25]} entries={entries} pagesAmount={4} data={dataTable} searchTop searchBottom={false} />

}