import React, { useState, useEffect } from 'react';
import { withAuthorization } from '../Session';
import { compose } from 'recompose';
import { Card, Row, Col, Spinner, Badge, Button, InputGroup, FormControl, Alert, Container } from 'react-bootstrap';
import { withDatabase } from '../Database';
import { useParams, Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import LocalizedTime from "../Common/LocalizedTime";
import { useTranslation } from "react-i18next";
import { LocalizedPrice } from "../Common/LocalizedPrice";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";

import de from 'date-fns/locale/de';
registerLocale('de', de);

const INITIAL_DLFILES = [
	{
		portalUsername: '',
		portalPassword: '',
		articleLink: '',
		articleName: ''
	}
];
const INITIAL_DLFILE = {
	portalUsername: '',
	portalPassword: '',
	articleLink: '',
	articleName: ''
}

const SingleOffer = ({ firebase, database }) => {
	const { t, i18n } = useTranslation('common');
	const [users, setUsers] = useState(database.users);
	const [offers, setOffers] = useState(database.offers);
	const [sellRequests, setSellRequests] = useState(database.sellRequests);
	const [loading, setLoading] = useState(false);
	const [netPrice, setNetPrice] = useState('');
	const [offerPdf, setOfferPdf] = useState({});
	const [orderConfirmationPdf, setOrderConfirmationPdf] = useState({});
	const [licencesPdf, setLicencesPdf] = useState({});
	const [invoicePdf, setInvoicePdf] = useState({});
	const [orderNotification, setOrderNotification] = useState(false);
	const [confirmationNotification, setConfirmationNotification] = useState(false);
	const [orderPdfError, setOrderPdfError] = useState('');
	const [offerPdfNotification, setOfferPdfNotification] = useState(false);
	const [offerpdfError, setofferPdfError] = useState('');
	const [offerPdfUrl, setOfferPdfUrl] = useState('');
	const [confirmationPdfUrl, setConfirmationPdfUrl] = useState('');
	const [invoicePdfUrl, setInvoicePdfUrl] = useState('');
	const [licencesPdfUrl, setLicencesPdfUrl] = useState('');
	const [user, setUser] = useState({});
	const [validDate, setValidDate] = useState(new Date());
	const [actualOffer, setActualOffer] = useState({
		cartItems: [],
		comment: '',
		createdAt: firebase.intermediateTime,
		status: '',
		uid: '',
		overallCartPrice: ''
	});
	const [saveLoading, setSaveLoading] = useState(false);

	const [dlfiles, setDlfiles] = useState(INITIAL_DLFILES);

	const { offerId } = useParams();

	const [easybillId, setEasybillId] = useState("");
	const [ebConfirmation, setEbConfirmation] = useState("");
	const [ebOrder, setEbOrder] = useState("");

	/* Ported to Notifier
	const sendPushNotification = async (title, body, notificationFunction) => {
		notificationFunction(false);
		if (user && user.expoPushToken) {
			const message = {
				to: user.expoPushToken,
				sound: 'default',
				title,
				body,
				data: {}
			};
			await fetch('https://exp.host/--/api/v2/push/send', {
				mode: 'no-cors',
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Accept-encoding': 'gzip, deflate',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(message)
			});
		}
	};
	*/
	const getOfferPdfUrl = () => {
		const storageRef = firebase.storage.ref();
		const offersStorage = storageRef.child(`${actualOffer.offerId}`);
		const fileRef = offersStorage.child(`${actualOffer.offer.fileName}`);
		fileRef.getDownloadURL().then((url) => {
			setOfferPdfUrl(url);
		});
	};

	const getConfirmationPdfUrl = () => {
		const storageRef = firebase.storage.ref();
		const offersStorage = storageRef.child(`${actualOffer.offerId}`);
		const fileRef = offersStorage.child(`${actualOffer.order.confirmation.fileName}`);
		fileRef.getDownloadURL().then((url) => {
			setConfirmationPdfUrl(url);
		});
	};
	const getInvoicePdfUrl = () => {
		const storageRef = firebase.storage.ref();
		const offersStorage = storageRef.child(`${actualOffer.offerId}`);
		const fileRef = offersStorage.child(`${actualOffer.order.invoice.fileName}`);
		fileRef.getDownloadURL().then((url) => {
			setInvoicePdfUrl(url);
		});
	};

	const getLicencesPdfUrl = () => {
		const storageRef = firebase.storage.ref();
		const offersStorage = storageRef.child(`${actualOffer.offerId}`);
		const fileRef = offersStorage.child(`${actualOffer.order.licences.fileName}`);
		fileRef.getDownloadURL().then((url) => {
			setLicencesPdfUrl(url);
		});
	};

	const handleOfferPdf = (e) => {
		if (!offerPdf.name || netPrice == 0) {
			setofferPdfError('Invalid Input applied, aborting...');
		} else {
			setLoading(true);
			// Upload logic
			const storageRef = firebase.storage.ref();
			const offersStorage = storageRef.child(`${actualOffer.offerId}`);
			const fileRef = offersStorage.child(`${offerPdf.name}`);
			let tempDate = validDate;
			tempDate.setHours(23,59,59);
			fileRef
				.put(offerPdf)
				.then((snapshot) => {
					console.log('Uploaded successfully');
					const notifications = user ? 'notifications.offerAvailable.sent' : 'notifications.offerAvailableNewUser.sent';
					firebase.offersCollection.doc(actualOffer.offerId).update({
						[notifications] : null,
						offer: {
							netPrice: netPrice,
							fileName: offerPdf.name,
							createdAt: firebase.serverTimeStamp,
							validUntil: tempDate,
							easybillId
						},
						status: 'Available'
					});
					setOfferPdfNotification(true);
					setLoading(false);
				})
				.catch((err) => {
					setofferPdfError('Upload did not work, aborting...');
					setLoading(false);
				});
		}
	};

	const handleOrder = async (e) => {
		if (!licencesPdf.name || !invoicePdf.name) setOrderPdfError('You need to provide both files.');
		else {
			const batch = firebase.db.batch();
			const offerRef = firebase.offersCollection.doc(actualOffer.offerId);
			const storageRef = firebase.storage.ref();
			const offersStorage = storageRef.child(`${actualOffer.offerId}`);
			if (licencesPdf.name) {
				const fileRef = offersStorage.child(`${licencesPdf.name}`);
				await fileRef.put(licencesPdf).then(() => {
					batch.update(offerRef, {
						'order.licences': { fileName: licencesPdf.name, createdAt: firebase.serverTimeStamp }
					});
				});
			}

			if (invoicePdf.name) {
				const fileRef = offersStorage.child(`${invoicePdf.name}`);
				await fileRef.put(invoicePdf).then(() => {
					batch.update(offerRef, {
						'order.invoice': { fileName: invoicePdf.name, createdAt: firebase.serverTimeStamp, easybillId: ebOrder },
					});
				});
			}

			if (licencesPdf.name && invoicePdf.name) {
				batch.update(offerRef, {
					'notifications.filesAvailable.sent': null,
					status: 'Completed'
				});
				setOrderNotification(true);
			}
			batch
				.commit()
				.then(() => {
					/* Ported to Notifier
					SendMail({ subject: t('mail.filesavailable.subject'), salutation: t('mail.filesavailable.salutation'), message: t('mail.filesavailable.message'), email: user.email });
					*/
				})
				.catch((err) => {
					setOrderPdfError(err.msg);
				});
		}
	};

	const handleConfirmation = async (e) => {
		if (!orderConfirmationPdf.name) setOrderPdfError('No confirmation file provided!');
		else {
			const batch = firebase.db.batch();
			const offerRef = firebase.offersCollection.doc(actualOffer.offerId);
			const storageRef = firebase.storage.ref();
			const offersStorage = storageRef.child(`${actualOffer.offerId}`);
			const fileRef = offersStorage.child(`${orderConfirmationPdf.name}`);

			await fileRef.put(orderConfirmationPdf).then(() => {
				batch.update(offerRef, {
					'order.confirmation': { fileName: orderConfirmationPdf.name, createdAt: firebase.serverTimeStamp, easybillId: ebConfirmation }
				});
			});

			batch.update(offerRef, {
				'notifications.confirmationAvailable.sent': null,
				status: 'Confirmed'
			});
			setConfirmationNotification(true);

			batch
				.commit()
				.then(() => {
					/* Ported to Notifier
					SendMail({ subject: t('mail.confirmationavailable.subject'), salutation: t('mail.confirmationavailable.salutation'), message: t('mail.confirmationavailable.message'), email: user.email });
					*/
				})
				.catch((err) => {
					setOrderPdfError(err.msg);
				});
		}
	};
	useEffect(() => {
		setOffers(database.offers);
	}, []);



	useEffect(
		() => {
			setOffers(database.offers);
			const [filteredOffer] = database.offers.filter((offer) => offer.offerId === offerId);
			setActualOffer(filteredOffer);
			if (filteredOffer?.downloadFiles) {
				setDlfiles(filteredOffer.downloadFiles);
			}
			if (actualOffer) {
				if (actualOffer.status != 'Pending' && actualOffer.offer) {
					getOfferPdfUrl();
				}
				if (actualOffer.status == 'Confirmed' && actualOffer.order) {
					getConfirmationPdfUrl();
				}
				if (actualOffer.status == 'Completed' && actualOffer.order) {
					getInvoicePdfUrl();
					getLicencesPdfUrl();
				}
				if (actualOffer && users) {
					const [userObj] = users.filter((obj) => actualOffer.uid == obj.uid);
					setUser(userObj);
				}
				/* Ported to Notifier
				if (offerPdfNotification) {
					(async function doSendPushNotification() {
						console.log('about to send push notification');
						await sendPushNotification(
							'New Offer',
							'A new offer has been added to your offers overview. Please have a look. We would be happy to process this offer as an order.',
							setOfferPdfNotification
						);
					})();
				}
				*/
				/* Ported to Notifier (filesAvailable)
				if (orderNotification) {
					(async function doSendPushNotification() {
						console.log('about to send push notification');
						await sendPushNotification(
							'Order completed',
							'Your order has been completed. Licences and invoice have been added to your request. Thank you for choosing us.',
							setOrderNotification
						);
					})();
				}
				*/
				/* Ported to Notifier
				if (confirmationNotification) {
					(async function doSendPushNotification() {
						console.log('about to send push notification');
						await sendPushNotification(
							'Order confirmation',
							'Your order has been updated. Order confirmation was added to your request. Thank you for choosing us.',
							setConfirmationNotification
						);
					})();
				}
				*/
			}
		},
		[database.offers, offerPdf, offerPdfNotification, offers, offerId, users]
	);

	const doMarkAsPaid = (offerId) => {
		firebase.doUpdateOffer(offerId, { paymentStatus: { paid: true, createdAt: firebase.serverTimeStamp } });
	}

	const addFileInput = () => {
		setDlfiles([...dlfiles, INITIAL_DLFILE]);
		console.log(dlfiles);
	}

	const doSaveFiles = () => {
		setSaveLoading(true);
		firebase.doUpdateOffer(actualOffer.offerId, { downloadFiles: dlfiles });
		setSaveLoading(false);
	}

	return (
		<Container className="my-5">
			<Row>
				<Col md={12}>
					<Link to={ROUTES.ADMIN_MYUSERS} className="mb-3">
						{t('navigation.myusers')}
					</Link>
					{' / '}
					<Link to={ROUTES.ADMIN_OFFERS} className="mb-3">
						{t('navigation.manageoffers')}
					</Link>
					{actualOffer && <Card className="my-3 shadow-none">
						<Card.Header>
							Offer Request from {actualOffer.createdAt &&
								actualOffer.createdAt.toDate().toLocaleString()}{' '}
							{actualOffer.status == 'Pending' && <Badge variant="warning">Pending</Badge>}
							{actualOffer.status == 'Available' && <Badge variant="primary">Available</Badge>}
							{actualOffer.status == 'Completed' && <Badge variant="success">Completed</Badge>}
							{actualOffer.status == 'Declined' && <Badge variant="danger">Declined</Badge>}
							{actualOffer.status == 'Ordered' && <Badge variant="info">Ordered</Badge>}
							{actualOffer.status == 'Confirmed' && <Badge variant="secondary">Confirmed</Badge>}
							<small className="float-right">OfferId: {actualOffer.offerId}</small>
						</Card.Header>
						<Card.Body>
							<Row className="mb-5">
								<Col xs={2} className="text-bold small text-left">{t('cart.thproductQuantity')}</Col>
								<Col xs={6} className="text-bold small">{t('cart.thproductName')}</Col>
								<Col xs={2} className="text-bold small text-right">{t('cart.thsinglePrice')}</Col>
								<Col xs={2} className="text-bold small text-right">{t('cart.thprice')}</Col>
								{actualOffer.cartItems.map(item => {
									return <><Col xs={2} className="text-left">
										{item.productQuantity}</Col>
										<Col xs={6}>{item.productName}</Col>
										<Col className="text-right" xs={2}><LocalizedPrice priceString={(item.price).toString()} /></Col>
										<Col className="text-right" xs={2}><LocalizedPrice priceString={(item.price * item.productQuantity).toString()} /></Col>

									</>
								})}
								<Col xs={2} className="text-bold small"></Col>
								<Col xs={2} className="text-bold small"></Col>
								<Col xs={6} className="text-bold text-right">{t('cart.overall')}:</Col>
								<Col xs={2} className="text-bold text-right"><LocalizedPrice priceString={actualOffer.overallCartPrice.toString()}></LocalizedPrice></Col>
							</Row>
							<Row>
								<Col xs={12} md={4}>
									<div className="font-weight-bold p-3">Customer Details</div>
								</Col>
								<Col xs={12} md={8}>
									<div className="bg-light p-3">
										{users.filter((user) => user.uid === actualOffer.uid).map((actualUser, index) => {
											return (
												<div className="customeradress">
													<p>{actualUser.company}</p>
													<p>{actualUser.name}</p>
													<p>{actualUser.email}</p>
													<p>{actualUser.zip}{' '}{actualUser.city}</p>
													<p>{actualUser.street}</p>
													<p>{actualUser.country}</p>
													<p>{actualUser.vat}</p>
												</div>
											);
										})}
									</div>
								</Col>
							</Row>
							<Card className="my-2">
								<Card.Header>
									Section: Offer (PDF){' '}
									{actualOffer.status == 'Pending' && <Badge variant="info">Action needed</Badge>}
								</Card.Header>
								{actualOffer.status == 'Pending' && (
									<Card.Body>
										<InputGroup className="my-3">
											<InputGroup.Prepend>
												<InputGroup.Text id="netprice-addon">Net Price:</InputGroup.Text>
											</InputGroup.Prepend>
											<FormControl
												placeholder="Net Price"
												type="number"
												aria-describedby="netprice-addon"
												step="any"
												value={netPrice}
												onChange={(event) => setNetPrice((event.target.value).replace(/,/g, '.'))}
											/>
										</InputGroup>

										<Row>
											<Col xs={12}>
												<label for="validDate" className="d-block">Valid until</label>
											</Col>
											<Col xs={12}>
												<DatePicker id="validDate" locale="de" selected={validDate} onChange={date => setValidDate(date)} />
											</Col>
											<Col xs={12}>
												<FormControl
													placeholder="Easybill Offer Number"
													type="text"
													aria-describedby="netprice-addon"
													value={easybillId}
													onChange={(event) => setEasybillId((event.target.value))}
												/>
											</Col>
										</Row>
										<InputGroup className="my-3">
											<label for="offerpdf">Offer PDF</label>
											<FormControl
												id="offerpdf"
												type="file"
												onChange={(event) => setOfferPdf(event.target.files[0])}
											/>
										</InputGroup>
										<Button variant="primary" onClick={handleOfferPdf}>
											Submit {loading && <Spinner animation="grow" size="sm" />}
										</Button>
										{offerpdfError && (
											<Alert variant="warning" className="my-2">
												{offerpdfError}
											</Alert>
										)}
									</Card.Body>
								)}
								{actualOffer.status != 'Pending' &&
									actualOffer.offer && (
										<Card.Body>
											<p>
												<a href={offerPdfUrl} target="_blank">
													{actualOffer.offer.fileName}
												</a>{' '}
									from{' '}
												{actualOffer.offer.createdAt &&
													actualOffer.offer.createdAt.toDate().toLocaleString()}</p>
											<p>Net Price: <LocalizedPrice priceString={actualOffer.offer.netPrice.toString()}></LocalizedPrice></p>
											<p>Easybill Nr: {actualOffer.offer.easybillId}</p>
										</Card.Body>
									)}
							</Card>

							<Card className="my-2">
								<Card.Header>
									Section: Confirmation (PDF){' '}
									{actualOffer.status == 'Ordered' && <Badge variant="info">Action needed</Badge>}
								</Card.Header>
								{actualOffer.status == 'Ordered' && (
									<Card.Body>
										<InputGroup className="my-3">
											<label for="confirmationPdf">Order confirmation (PDF)</label>
											<FormControl
												id="confirmationPdf"
												type="file"
												onChange={(event) => setOrderConfirmationPdf(event.target.files[0])}
											/>
										</InputGroup>
										<Row>
											<Col xs={12}>
												<FormControl
													placeholder="Easybill Confirmation Number"
													type="text"
													aria-describedby="netprice-addon"
													value={ebConfirmation}
													onChange={(event) => setEbConfirmation((event.target.value))}
												/>
											</Col>
										</Row>
										<Button variant="primary" onClick={handleConfirmation}>
											Submit {loading && <Spinner animation="grow" size="sm" />}
										</Button>
										{orderPdfError && (
											<Alert variant="warning" className="my-2">
												{orderPdfError}
											</Alert>
										)}
									</Card.Body>
								)}
								{actualOffer.status != 'Ordered' &&
									actualOffer.order &&
									actualOffer.order.confirmation && (
										<Card.Body>
											<a href={confirmationPdfUrl} target="_blank">
												{actualOffer.order.confirmation.fileName}
											</a>{' '}
									from{' '}
											{actualOffer.order.confirmation.createdAt &&
												actualOffer.order.confirmation.createdAt.toDate().toLocaleString()}
											<p>Easybill Nr: {actualOffer.order.confirmation.easybillId}</p>
										</Card.Body>
									)}
							</Card>
							<Card className="my-2">
								<Card.Header>
									Section: Order Files (PDF){' '}
									{actualOffer.status == 'Confirmed' && <Badge variant="info">Action needed</Badge>}
								</Card.Header>
								{actualOffer.status == 'Confirmed' && (
									<Card.Body>
										<InputGroup className="my-3">
											<label for="licencesPdf">Licences (PDF)</label>
											<FormControl
												id="licencesPdf"
												type="file"
												onChange={(event) => setLicencesPdf(event.target.files[0])}
											/>
											<label for="invoicePdf">Invoice (PDF)</label>
											<FormControl
												id="invoicePdf"
												type="file"
												onChange={(event) => setInvoicePdf(event.target.files[0])}
											/>
										</InputGroup>
										<Row>
											<Col xs={12}>
												<FormControl
													placeholder="Easybill Invoice Number"
													type="text"
													aria-describedby="netprice-addon"
													value={ebOrder}
													onChange={(event) => setEbOrder((event.target.value))}
												/>
											</Col>
										</Row>
										<Button variant="primary" onClick={handleOrder}>
											Submit {loading && <Spinner animation="grow" size="sm" />}
										</Button>
										{orderPdfError && (
											<Alert variant="warning" className="my-2">
												{orderPdfError}
											</Alert>
										)}
									</Card.Body>
								)}
								{actualOffer.status != 'Confirmed' &&
									actualOffer.order &&
									actualOffer.order.invoice &&
									actualOffer.order.licences && (
										<Card.Body>
											<a href={invoicePdfUrl} target="_blank">
												{actualOffer.order.invoice.fileName}
											</a>{' '}
									from{' '}
											{actualOffer.order.invoice.createdAt &&
												actualOffer.order.invoice.createdAt.toDate().toLocaleString()}
											<br />
											<a href={licencesPdfUrl} target="_blank">
												{actualOffer.order.licences.fileName}
											</a>{' '}
									from{' '}
											{actualOffer.order.licences.createdAt &&
												actualOffer.order.licences.createdAt.toDate().toLocaleString()}
											<br />
											<p>Easybill Nr: {actualOffer.order.invoice.easybillId}</p>

										</Card.Body>
									)}
							</Card>
							<Card className="my-2">
								<Card.Header>
									Section: Download Files{' '}
								</Card.Header>
								<Card.Body>

									{!actualOffer.downloadFiles && (
										<div>No files added.</div>
									)}
									{dlfiles.map((dlfile, index) => {
										return <><InputGroup className="my-1" key={index}>
											<div className="w-100">
												<h1 className="h4">#{index + 1}</h1>
											</div>
											<div className="w-100 d-flex justify-content-center align-items-baseline">
												<label className="pr-2">portalUsername:</label>
												<FormControl
													placeholder=""
													type="text"
													value={dlfile.portalUsername}
													onChange={(event) => setDlfiles(dlfiles.map((item, i) =>
														i === index ? { ...item, portalUsername: event.target.value } : item
													))}
												/>
											</div>
											<div className="w-100 d-flex justify-content-center align-items-baseline">

												<label className="pr-2">portalPassword:</label>
												<FormControl
													placeholder=""
													type="text"
													value={dlfile.portalPassword}
													onChange={(event) => setDlfiles(dlfiles.map((item, i) =>
														i === index ? { ...item, portalPassword: event.target.value } : item
													))}
												/>											</div>

											<div className="w-100 d-flex justify-content-center align-items-baseline">

												<label className="pr-2">articleName:</label>
												<FormControl
													placeholder=""
													type="text"
													value={dlfile.articleName}
													onChange={(event) => setDlfiles(dlfiles.map((item, i) =>
														i === index ? { ...item, articleName: event.target.value } : item
													))}
												/>											</div>

											<div className="w-100 d-flex justify-content-center align-items-baseline">

												<label className="pr-2">articleLink:</label>
												<FormControl
													placeholder=""
													type="text"
													value={dlfile.articleLink}
													onChange={(event) => setDlfiles(dlfiles.map((item, i) =>
														i === index ? { ...item, articleLink: event.target.value } : item
													))}
												/>											</div>

										</InputGroup>
											<hr className="w-100" />
										</>
									})}

									<Button variant="primary" onClick={() => addFileInput()}>Add file</Button>
									<Button variant="secondary" onClick={() => doSaveFiles()}>Save Files {saveLoading && <Spinner animation="grow" size="sm" />}</Button>
								</Card.Body>
							</Card>
						</Card.Body>
						<Card.Footer>
							{actualOffer.status !== "Pending" && actualOffer.status !== "Declined" && actualOffer.status !== "Available" && (
								<>
									{!actualOffer.paymentStatus && (<Button onClick={() => doMarkAsPaid(actualOffer.offerId)}>Mark as paid</Button>)}
									{actualOffer.paymentStatus && <>Paid on: <LocalizedTime seconds={actualOffer.paymentStatus.createdAt.seconds * 1000}></LocalizedTime></>}
								</>
							)}
						</Card.Footer>
					</Card>}
				</Col>
			</Row>
		</Container>
	);
};

const condition = (authUser) => authUser && !!authUser.isAdmin == true;

const SingleOfferPage = compose(withAuthorization(condition), withDatabase)(SingleOffer);

export default SingleOfferPage;
