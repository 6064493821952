import React, {useState, useEffect, Suspense, useLayoutEffect, useContext} from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import {Container, Row, Col, Navbar, Nav} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';
import {compose} from 'recompose';
import {useTranslation} from "react-i18next";
import {library} from '@fortawesome/fontawesome-svg-core';
import {fab} from '@fortawesome/free-brands-svg-icons';
import {faUser, faStickyNote, faCoins, faHome} from '@fortawesome/free-solid-svg-icons';

import Navigation from '../Navigation';
import SignInPage from '../SignIn';
import HomePage from '../Home';
import UsersPage from '../Users';
import SignUpPage from '../SignUp';
import MyUsersPage from '../Users/MyUsers';
import {OffersPage, SingleOfferPage} from '../Offers';

import {withAuthentication, NoAdminWarning, AuthUserContext} from '../Session';

import {DatabaseContext} from '../Database';

import * as ROUTES from '../../constants/routes';
import {SellRequestsPage} from '../SellRequests';
import ProductsPage from '../Products';
import LandingPage from '../Landing';
import MyOffersPage from '../Offers/MyOffers';
import MyOrdersPage from '../Offers/MyOrders';
import MyAccountPage from '../Account';
import PwForgetPage from '../PwForget';
import {Terms, Privacy, Imprint, Cancellation} from "../Pages";
import _ from "lodash";

library.add(fab, faUser, faStickyNote, faCoins, faHome);

export const CartContext = React.createContext(null);


const App = ({firebase, authUser}) => {

        const [users, setUsers] = useState([]);
        const [offers, setOffers] = useState([]);
        const [products, setProducts] = useState([]);
        const [categories, setCategories] = useState([]);
        const [sellRequests, setSellRequests] = useState([]);
        const {t, i18n} = useTranslation('common');
        const [cartItems, setCartItems] = useState([]);
        const [showCart, setShowCart] = useState(false);
        const [myFavorites, setMyFavorites] = useState([]);
        const authUserContext = useContext(AuthUserContext);
        const addToCart = (product) => {
            setCartItems(oldArray => [...oldArray, product]);
        }
        const removeCartItemByIndex = (index) => {
            setCartItems(cartItems.filter((item, i) => i !== index));
        }
        const updateQuantityByIndex = (index, quantity) => {

            if (isNaN(quantity) || quantity == "" || quantity < 1 || quantity > 9999)
                return;
            setCartItems(
                cartItems.map((item, key) => {
                    if (index === key) {
                        let newItem = item;
                        newItem.productQuantity = quantity;
                        return newItem;
                    }
                    return item;
                })
            );
        }
        const resetCart = () => {
            setCartItems([]);
        }

        const getOverAllCartPrice = () => {
            let price = 0;
            cartItems.forEach(item => {
                price += item.productQuantity * item.price;
            })
            return price;
        }

        useEffect(() => {
            const localCartItems = JSON.parse(localStorage.getItem('cartItems'));
            if (localCartItems) {
                setCartItems(localCartItems);
            }
            const localProductsArray = JSON.parse(localStorage.getItem('productsArray'));
            if (localProductsArray)
                setProducts(localProductsArray);

            const localCategoriesArray = JSON.parse(localStorage.getItem('categoriesArray'));
            if (localCategoriesArray)
                setCategories(localCategoriesArray);
        }, []);

        /**
         * Listeners if admin
         */
        useEffect(() => {
            let userSubscriber;
            let offerSubscriber;
            if (firebase.auth.currentUser) {
                firebase.admin(firebase.auth.currentUser.uid).then(doc => {
                    if (doc.exists) {
                        // Fetch users.
                        userSubscriber = firebase.usersCollection.onSnapshot((querySnapshot) => {
                            const usersArray = [];
                            querySnapshot.forEach((doc) => {
                                const user = {
                                    uid: doc.id,
                                    ...doc.data()
                                };
                                if (!user.createdAt && querySnapshot.metadata.hasPendingWrites) {
                                    user.createdAt = firebase.intermediateTime;
                                }
                                usersArray.push(user);
                            });
                            /**
                             * Set favorite users
                             */
                            let myUser = _.find(usersArray, {uid: firebase.auth.currentUser.uid})
                            setMyFavorites(myUser.myFavorites ?? []);
                            setUsers(usersArray);
                        });
                        // Fetch offers.
                        offerSubscriber = firebase.offersCollection.orderBy('createdAt', 'desc').onSnapshot((querySnapshot) => {
                            const offersArray = [];
                            querySnapshot.forEach((doc) => {
                                const offer = {
                                    offerId: doc.id,
                                    ...doc.data()
                                };
                                if (!offer.createdAt && querySnapshot.metadata.hasPendingWrites) {
                                    offer.createdAt = firebase.intermediateTime;
                                }
                                if (offer.paymentStatus && !offer.paymentStatus.createdAt && querySnapshot.metadata.hasPendingWrites) {
                                    offer.paymentStatus.createdAt = firebase.intermediateTime;
                                }
                                offersArray.push(offer);
                            });
                            setOffers(offersArray);
                        });
                    } else {
                        // is no admin user, populate only own offers.
                        offerSubscriber = firebase.offersCollection.where('uid', '==', firebase.auth.currentUser.uid).orderBy('createdAt', 'desc').onSnapshot((querySnapshot) => {
                            const offersArray = [];
                            querySnapshot.forEach((doc) => {
                                const offer = {
                                    offerId: doc.id,
                                    ...doc.data()
                                };
                                if (!offer.createdAt && querySnapshot.metadata.hasPendingWrites) {
                                    offer.createdAt = firebase.intermediateTime;
                                }
                                if (offer.paymentStatus && !offer.paymentStatus.createdAt && querySnapshot.metadata.hasPendingWrites) {
                                    offer.paymentStatus.createdAt = firebase.intermediateTime;
                                }
                                offersArray.push(offer);
                            });
                            setOffers(offersArray);
                        })
                    }
                })
            }

            return () => {
                if (userSubscriber)
                    userSubscriber();

                if (offerSubscriber)
                    offerSubscriber();
            };
        }, [firebase.auth.currentUser])


        useEffect(
            () => {
                localStorage.setItem('cartItems', JSON.stringify(cartItems));

                const productSubscriber = firebase.productsCollection.onSnapshot((querySnapshot) => {
                    const productsArray = [];
                    querySnapshot.forEach((doc) => {
                        const product = {
                            productId: doc.id,
                            ...doc.data()
                        };
                        if (!product.createdAt && querySnapshot.metadata.hasPendingWrites) {
                            product.createdAt = firebase.intermediateTime;
                        }
                        productsArray.push(product);
                    });
                    setProducts(productsArray);
                    localStorage.setItem('productsArray', JSON.stringify(productsArray));
                });

                const categorySubscriber = firebase.categoriesCollection.onSnapshot((querySnapshot) => {
                    const realOrderArray = [];
                    querySnapshot.forEach((doc) => {
                        const category = {
                            categoryId: doc.id,
                            ...doc.data()
                        };
                        switch (category.categoryId) {
                            case "QDlXd2VpzUjdl6iKi4Mu":
                                realOrderArray[0] = category;
                                break;
                            case "iSJZDpqOMxtylBjbr1cX":
                                realOrderArray[1] = category;
                                break;
                            case "8dB5Vte6p79N3rNAE9A4":
                                realOrderArray[2] = category;
                                break;
                            case "0GddCmRBkTTjSGOjhIep":
                                realOrderArray[3] = category;
                                break;
                            case "MWuy6YcT4uSErIitVV6f":
                                realOrderArray[4] = category;
                                break;
                            case "Xsh6akoi8pOOlGxrg4Uc":
                                realOrderArray[5] = category;
                                break;
                            case "Ej9Id64drq180YFAXz1u":
                                realOrderArray[6] = category;
                                break;
                            default:
                                break;
                        }
                    });
                    setCategories(realOrderArray);
                    localStorage.setItem('categoriesArray', JSON.stringify(realOrderArray));

                });

                // firebase.sellRequests().then((querySnapshot) => {
                // 	const sellRequestsArray = [];
                // 	querySnapshot.forEach((doc) => {
                // 		const sellRequest = {
                // 			sellRequestId: doc.id,
                // 			...doc.data()
                // 		};
                // 		sellRequestsArray.push(sellRequest);
                // 	});
                // 	setSellRequests(sellRequestsArray);
                // });

                return () => {
                    // userSubscriber();
                    // offerSubscriber();
                    productSubscriber();
                    categorySubscriber();
                };
            },
            [firebase, cartItems]
        );
        return (
            <Suspense fallback="loading">
                <Router>
                    <div className="mainWrapper">
                        <CartContext.Provider value={{
                            cartItems,
                            updateQuantityByIndex,
                            addToCart,
                            removeCartItemByIndex,
                            resetCart,
                            getOverAllCartPrice,
                            showCart,
                            setShowCart
                        }}>
                            <DatabaseContext.Provider
                                value={{users, offers, products, sellRequests, categories, myFavorites}}>
                                <Navigation/>
                                <Route path={ROUTES.SIGN_IN} component={SignInPage}/>
                                <Route path={ROUTES.PASSWORD_FORGET} component={PwForgetPage}/>
                                <Route path={ROUTES.SIGN_UP} component={SignUpPage}/>
                                <Route path={ROUTES.TERMS} component={Terms}/>
                                <Route path={ROUTES.PRIVACY} component={Privacy}/>
                                <Route path={ROUTES.IMPRINT} component={Imprint}/>
                                <Route path={ROUTES.CANCELLATION} component={Cancellation}/>
                                <Route exact path={ROUTES.LANDING} component={LandingPage}/>
                                <Route exact path={ROUTES.ADD_PRODUCT}>
                                    <LandingPage/>
                                </Route>
                                {/* <Route exact path={ROUTES.HOME} component={HomePage} />
						<Route exact path={ROUTES.USERS} component={UsersPage} />
						<Route exact path={ROUTES.OFFERS} component={OffersPage} />
						<Route exact path={`${ROUTES.OFFERS}/:offerId`} component={SingleOfferPage} />
						<Route exact path={ROUTES.SELLREQUESTS} component={SellRequestsPage} /> */}
                                {/* ADMIN ROUTES */}
                                <Route exact path={ROUTES.ADMIN_PRODUCTS} component={ProductsPage}/>
                                <Route exact path={ROUTES.ADMIN_PRODUCT} component={ProductsPage}/>
                                <Route exact path={ROUTES.ADMIN_OFFERS} component={OffersPage}/>
                                <Route exact path={ROUTES.ADMIN_OFFER} component={SingleOfferPage}/>
                                <Route exact path={ROUTES.ADMIN_USERS} component={UsersPage}/>
                                <Route exact path={ROUTES.ADMIN_MYUSERS} component={MyUsersPage}/>
                                {/* USER ROUTES */}
                                <Route exact path={ROUTES.MY_OFFERS} component={MyOffersPage}/>
                                <Route exact path={ROUTES.MY_ORDERS} component={MyOrdersPage}/>
                                <Route exact path={ROUTES.ACCOUNT} component={MyAccountPage}/>

                            </DatabaseContext.Provider>
                            <Route exact path={ROUTES.RESTRICTED} component={NoAdminWarning}/>
                            <Navbar className="footer">
                                <Container>
                                    <Nav>
                                        <LinkContainer to={ROUTES.IMPRINT}>
                                            <Nav.Link to={ROUTES.IMPRINT}
                                            >{t('navigation.imprint')}</Nav.Link>
                                        </LinkContainer>
                                        <LinkContainer to={ROUTES.PRIVACY}>
                                            <Nav.Link to={ROUTES.PRIVACY}
                                            >{t('navigation.privacy')}</Nav.Link>
                                        </LinkContainer>
                                        <LinkContainer to={ROUTES.CANCELLATION}>
                                            <Nav.Link to={ROUTES.CANCELLATION}
                                            >{t('navigation.cancellation')}</Nav.Link>
                                        </LinkContainer>
                                        <LinkContainer to={ROUTES.TERMS}>
                                            <Nav.Link to={ROUTES.TERMS}
                                            >{t('navigation.agb')}</Nav.Link>
                                        </LinkContainer>
                                    </Nav>
                                </Container>
                            </Navbar>
                        </CartContext.Provider>
                    </div>
                </Router>
            </Suspense>
        );
    }
;

export default compose(withAuthentication)(App);
