import React, { useState, useEffect, useContext } from 'react';
import { withAuthorization, withAuthentication } from '../Session';
import { compose } from 'recompose';
import { Card, Row, Col, Spinner, Table, Badge } from 'react-bootstrap';
import { withDatabase } from '../Database';
import { LinkContainer } from 'react-router-bootstrap';
import * as ROUTES from '../../constants/routes';

const SellRequests = ({ firebase, database }) => {
	const [ users, setUsers ] = useState(database.users);
	const [ offers, setOffers ] = useState(database.offers);
	const [ sellRequests, setSellRequests ] = useState(database.sellRequests);
	const [ loading, setLoading ] = useState(false);

	console.log(offers);
	useEffect(
		() => {
			setUsers(database.users);
			setSellRequests(database.sellRequests);
			console.log(users);
			console.log(sellRequests);
		},
		[ database.users, database.sellRequests ]
	);
	return (
		<Row>
			{sellRequests
				.sort(({ createdAt: previous }, { createdAt: current }) => current - previous)
				.map((sellRequest, key) => {
					return (
						<Col md={12} className="mt-3" key={key}>
							<Card>
								<Card.Body>
									<Row>
										<Col md={6}>
											<Card.Title>
												{users
													.filter((user) => user.uid == sellRequest.uid)
													.map((actualUser, index) => (
														<div key={index}>
															{actualUser.company}
															<br />
															<small>{actualUser.email}</small>
														</div>
													))}
											</Card.Title>
											<Card.Subtitle>
												<small>{sellRequest.createdAt.toDate().toLocaleString()}</small>
											</Card.Subtitle>
										</Col>
										<Col md={6}>
											<h6>{sellRequest.manufacturer}</h6>
											<Table striped bordered hover size="sm" className="small-table">
												<thead>
													<tr>
														<th>Version</th>
														<th>Amount</th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td>{sellRequest.version}</td>
														<td>{sellRequest.amount}</td>
													</tr>
												</tbody>
											</Table>
										</Col>
									</Row>
									{sellRequest.comment && (
										<Row className="mt-2">
											<Col md={6}>Comments:</Col>
											<Col md={6}>{sellRequest.comment}</Col>
										</Row>
									)}
									{!sellRequest.comment && (
										<Row className="mt-2">
											<Col>No comment applied.</Col>
										</Row>
									)}
								</Card.Body>
								{/* <Card.Footer>
								 <LinkContainer to={`${ROUTES.OFFERS}/${offer.offerId}`}>
								 	<Card.Link>Manage Offer</Card.Link>
								 </LinkContainer> 
								</Card.Footer> */}
							</Card>
						</Col>
					);
				})}
		</Row>
	);
};

const condition = (authUser) => authUser && !!authUser.isAdmin == true;

const SellRequestsPage = compose(withAuthorization(condition), withDatabase)(SellRequests);

export default SellRequestsPage;
