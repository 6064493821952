import React, { useState, useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { Form, Button, Spinner, Alert, Container, Row, Col, Nav } from "react-bootstrap";
import { LinkContainer } from 'react-router-bootstrap';

import { withFirebase } from "../Firebase";
import { compose } from "recompose";
import { useTranslation } from "react-i18next";

import * as ROUTES from "../../constants/routes";

const PwForget = ({ firebase }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [valid, setValid] = useState(false);
  const [error, setError] = useState("");
  const [errorCount, setErrorCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [waitingTime, setWaitingTime] = useState(0);
  const history = useHistory();
  const { t, i18n } = useTranslation('common');
  const [success, setSuccess] = useState(false);


  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    firebase.doCreatePasswordResetRequest(email).then(() => {
      setSuccess(true);
      setLoading(false);
    }).catch(err => {
      setSuccess(true);
      setLoading(false);
    })
  };

  useEffect(() => {
    setValid(
      email.length !== 0 && email.includes("@")
    );

  }, [email]);

  return (
    <div className="fullpage-background">
      <Form onSubmit={handleSubmit} className="mt-5 mb-3">
        <Container>
          <div className="row-fluid">
            <Col lg={4} md={6} className="box-border-left shadow p-5 p-sm-3 p-lg-5 bg-light">
              <h1>{t('pwforget.title')}</h1>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>{t('signin.email')}</Form.Label>
                <Form.Control
                  type="email"
                  placeholder={t('signin.entermail')}
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </Form.Group>
              <Button variant="primary" type="submit" disabled={!valid || disabled || success}>
                {t('pwforget.send')} {loading && <Spinner animation="grow" size="sm" />}
              </Button>
              <div className="my-3 text-muted ">
                <LinkContainer to={ROUTES.SIGN_IN}>
                  <Nav.Link className="d-inline py-0 px-1">&laquo; {t('pwforget.back')}</Nav.Link>
                </LinkContainer>
              </div>
              {success && (
                <Alert variant="info" className="my-3">
                  {t('pwforget.message')}
                </Alert>
              )}

            </Col>
          </div>
        </Container>
      </Form>
    </div >
  );
};

const PwForgetPage = compose(withRouter, withFirebase)(PwForget);

export default PwForgetPage;
