import React, {useState, useEffect, useContext, createRef} from "react";
import {Card, Row, Col, Spinner, Container, Button, Modal, Form, CardDeck, Toast} from "react-bootstrap";
import {useTranslation} from "react-i18next";

const Imprint = ({firebase, database}) => {

    const {t, i18n} = useTranslation('common');
    return <>
        <Container className="my-5">
            <h1 className="display-4">{t('pages.imprint.pagetitle')}</h1>
            {[...Array(6)].map((x, i) => {
                return (<>
                    <h5>{t(`pages.imprint.${i+1}.title`)}</h5>
                    {i+1 === 1 && (
                        <p>
                            Softwarebuddies GmbH<br/>
                            Leopoldstr. 2-8<br/>
                            32051 Herford<br/>
                            {t('pages.agb.germany')}<br/>
                            T. +49 5221 872 9620<br/>
                            E. office@softwarebuddies.eu<br/>
                        </p>
                    )}
                    {[...Array(2)].map((sub, index) => {
                        return (
                            <>
                                <p>{t(`pages.imprint.${i+1}.${index+1}`)}</p>
                            </>
                        )
                    })}
                </>)
            })
            }
        </Container>
    </>
}

export default Imprint;