import React, { useState, useEffect, useContext, createRef } from "react";
import { withAuthorization, withAuthentication } from "../Session";
import { compose } from "recompose";
import { Card, Row, Col, Spinner, Container, Button, Modal, Form } from "react-bootstrap";
import { withDatabase } from "../Database";
import { useTranslation } from "react-i18next";
import * as Icons from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataTable } from "../Common/Datatable";
import _ from "lodash";

const Products = ({ firebase, database }) => {
    const [categories, setCategories] = useState(database.categories);
    const [products, setProducts] = useState(database.products);
    const [loading, setLoading] = useState(false);
    const { t, i18n } = useTranslation('common');
    const [categoryName, setCategoryName] = useState('');
    const [iconName, setIconName] = useState('');
    const [iconPrefix, setIconPrefix] = useState('');
    const [categoryIdToBeUpdated, setCategoryIdToBeUpdated] = useState('');
    const [productIdToBeUpdated, setProductIdToBeUpdated] = useState('');
    const [product, setProduct] = useState({
        productName: '',
        categoryName: '',
        referencePrice: '',
        salePrice: '',
        isTopSeller: ''
    })
    const productRef = createRef();

    // Modal setters
    const [showCategoriesModal, setShowCategoriesModal] = useState(false);
    const handleCloseCategoriesModal = () => setShowCategoriesModal(false);
    const handleShowCategoriesModal = () => setShowCategoriesModal(true);

    const [showEditCategoryModal, setShowEditCategoryModal] = useState(false);
    const handleCloseEditCategoryModal = () => setShowEditCategoryModal(false);
    const handleShowEditCategoryModal = () => setShowEditCategoryModal(true);

    const [showCreateProductModal, setShowCreateProductModal] = useState(false);
    const handleCloseCreateProductModal = () => setShowCreateProductModal(false);
    const handleShowCreateProductModal = () => setShowCreateProductModal(true);

    const [showEditProductModal, setShowEditProductModal] = useState(false);
    const handleCloseEditProductModal = () => setShowEditProductModal(false);
    const handleShowEditProductModal = () => setShowEditProductModal(true);

    const [showStepCategoryModal, setShowStepCategoryModal] = useState(false);
    const handleCloseStepCategoryModal = () => setShowStepCategoryModal(false);
    const handleShowStepCategoryModal = () => setShowStepCategoryModal(true);

    const [showStepProductModal, setShowStepProductModal,] = useState(false);
    const handleCloseStepProductModal = () => setShowStepProductModal(false);
    const handleShowStepProductModal = () => setShowStepProductModal(true);

    const [categoryIdToBeDeleted, setCategoryIdToBeDeleted] = useState('');
    const [productIdToBeDeleted, setProductIdToBeDeleted] = useState('');

    // Tables
    const [categoriesTable, setCategoriesTable] = useState({
        columns: [
            {
                label: 'Name',
                field: 'name',
                width: 150,
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Name',
                },
                sort: 'asc'
            },
            {
                label: t('admin.products.action'),
                field: 'action',
                sort: 'disabled'
            }
        ],
        rows: []
    });
    const [productsTable, setProductsTable] = useState({
        columns: [
            {
                label: 'sortValue',
                field: 'sortValue',
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'sortValue',
                },
            },
            {
                label: t('admin.products.product'),
                field: 'productName',
                width: 150,
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Product',
                },
            },
            {
                label: t('admin.products.category'),
                field: 'categoryName',
                width: 270,
            },
            {
                label: t('admin.products.referenceprice'),
                field: 'referencePrice',
            },
            {
                label: t('admin.products.saleprice'),
                field: 'salePrice',
            },
            {
                label: 'uri',
                field: 'uri',
            },
            {
                label: t('admin.products.action'),
                field: 'action',
                sort: 'disabled'
            }
        ],
        rows: []
    });

    // Firebase APIs
    const doCreateCategory = () => {
        firebase.doCreateCategory({ categoryName, iconPrefix, iconName });
        setCategoryName('');
        setIconName('');
        setIconPrefix('');
    }
    const doDeleteCategory = (categoryId) => {
        firebase.doDeleteCategory(categoryId);
    }
    const doUpdateCategory = (categoryId) => {
        firebase.doUpdateCategory(categoryId, { categoryName, iconName, iconPrefix });
        setCategoryName('');
        setIconName('');
        setIconPrefix('');
    }
    const doCreateProduct = () => {
        firebase.doCreateProduct(product);
        setProduct({
            productName: '',
            categoryName: '',
            referencePrice: '',
            salePrice: '',
            sortValue: '',
            uri: '',
        });
    };
    const doDeleteProduct = (productId) => {
        firebase.doDeleteProduct(productId);
    }
    const doUpdateProduct = (productId) => {
        firebase.doUpdateProduct(productId, product);
        setProduct({
            productName: '',
            categoryName: '',
            referencePrice: '',
            salePrice: '',
            isTopSeller: '',
            sortValue: '',
            uri: '',
        });
    }

    useEffect(() => {
        /* categories fetching */
        let tempTable = { ...categoriesTable };
        while (tempTable.rows.length)
            tempTable.rows.pop();
        database.categories.map(category => {
            tempTable.rows.push({
                name: category.categoryName,
                icon: <FontAwesomeIcon icon={[category.iconPrefix, category.iconName]}></FontAwesomeIcon>,
                action:
                    <>
                        <Button variant="danger" size="sm" onClick={() => { setCategoryIdToBeDeleted(category.categoryId); handleShowStepCategoryModal(); }}><FontAwesomeIcon icon={Icons.faTrash}></FontAwesomeIcon></Button>
                        <Button variant="warning" size="sm" className="text-white" onClick={() => { setCategoryIdToBeUpdated(category.categoryId); setCategoryName(category.categoryName); setIconName(category.iconName); setIconPrefix(category.iconPrefix); handleShowEditCategoryModal(); }}><FontAwesomeIcon icon={Icons.faPen}></FontAwesomeIcon></Button>
                    </>,
            })
        });
        setCategoriesTable(tempTable);

        /* products fetching */
        let tempProductsTable = { ...productsTable };
        while (tempProductsTable.rows.length)
            tempProductsTable.rows.pop();
        let tempProducts = _.orderBy(database.products, ["sortValue"], ["asc"])
        tempProducts.map(product => {
            tempProductsTable.rows.push({
                sortValue: product.sortValue ?? '',
                productName: product.productName,
                categoryName: product.categoryName,
                referencePrice: product.referencePrice,
                salePrice: product.salePrice,
                uri: product.uri ?? '',
                action:
                    <>
                        <Button variant="danger" size="sm" onClick={() => { setProductIdToBeDeleted(product.productId); handleShowStepProductModal() }}><FontAwesomeIcon icon={Icons.faTrash}></FontAwesomeIcon></Button>
                        <Button variant="warning" size="sm" className="text-white" onClick={() => { setProduct(product); setProductIdToBeUpdated(product.productId); handleShowEditProductModal(); }}><FontAwesomeIcon icon={Icons.faPen}></FontAwesomeIcon></Button>
                    </>
            })
        })
        setProductsTable(tempProductsTable);

    }, [database.categories, database.products])


    return (
        <>
            <Modal show={showStepCategoryModal} onHide={handleCloseStepCategoryModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('admin.products.deletecategory')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t('admin.products.stepcategory')}</p>
                    <Button onClick={() => { doDeleteCategory(categoryIdToBeDeleted); handleCloseStepCategoryModal(); }}>Yes, proceed.</Button>
                </Modal.Body>
            </Modal>
            <Modal show={showStepProductModal} onHide={handleCloseStepProductModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('admin.products.deleteproduct')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t('admin.products.stepproduct')}</p>
                    <Button onClick={() => { doDeleteProduct(productIdToBeDeleted); handleCloseStepProductModal(); }}>Yes, proceed.</Button>
                </Modal.Body>
            </Modal>
            {/* Category creation modal */}
            <Modal show={showCategoriesModal} onHide={handleCloseCategoriesModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('admin.products.createcategory')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="formCategoryName">
                        <Form.Label>{t('admin.products.categoryname')}</Form.Label>
                        <Form.Control
                            type="string"
                            placeholder={t('admin.products.categoryname')}
                            value={categoryName}
                            onChange={(event) => setCategoryName(event.target.value)}
                        />
                    </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                    <Button className="bg-secondary" onClick={handleCloseCategoriesModal}>
                        {t('global.close')}
                    </Button>
                    <Button className="bg-primary" onClick={() => { doCreateCategory(); handleCloseCategoriesModal(); }}>
                        {t('global.save')}
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Category edit modal */}
            <Modal show={showEditCategoryModal} onHide={handleCloseEditCategoryModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('admin.products.editcategory')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="formCategoryName">
                        <Form.Label>{t('admin.products.categoryname')}</Form.Label>
                        <Form.Control
                            type="string"
                            placeholder={t('admin.products.categoryname')}
                            value={categoryName}
                            onChange={(event) => setCategoryName(event.target.value)}
                        />
                    </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                    <Button className="bg-secondary" onClick={handleCloseEditCategoryModal}>
                        {t('global.close')}
                    </Button>
                    <Button className="bg-primary" onClick={() => { doUpdateCategory(categoryIdToBeUpdated); handleCloseEditCategoryModal(); }}>
                        {t('global.save')}
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* create product modal */}
            <Modal show={showCreateProductModal} onHide={handleCloseCreateProductModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('admin.products.createproduct')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="formProductName">
                        <Form.Label>{t('admin.products.productname')}</Form.Label>
                        <Form.Control
                            type="string"
                            placeholder={t('admin.products.productname')}
                            value={product.productName}
                            onChange={(event) => setProduct({ ...product, productName: event.target.value })}
                        />
                    </Form.Group>
                    <Form.Group controlId="formProductCategory">
                        <Form.Label>{t('admin.products.category')}</Form.Label>
                        <Form.Control as="select" onClick={(event) => setProduct({ ...product, categoryName: event.target.value })}>
                            <option></option>
                            {database.categories.map(category => {
                                return <option value={category.categoryName} key={category.categoryId}>{category.categoryName}</option>
                            })}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="formReferencePrice">
                        <Form.Label>{t('admin.products.referenceprice')}</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder={t('admin.products.referenceprice')}
                            value={product.referencePrice}
                            step="any"
                            onChange={(event) => setProduct({ ...product, referencePrice: (event.target.value).replace(/,/g, '.') })}
                        />
                    </Form.Group>
                    <Form.Group controlId="formSalePrice">
                        <Form.Label>{t('admin.products.saleprice')}</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder={t('admin.products.saleprice')}
                            value={product.salePrice}
                            step="any"
                            onChange={(event) => setProduct({ ...product, salePrice: (event.target.value).replace(/,/g, '.') })}
                        />
                    </Form.Group>
                    <Form.Group controlId="formTopSeller">
                        <Form.Label>{t('admin.products.topseller')}</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={t('admin.products.topseller')}
                            value={product.topSeller}
                            onChange={(event) => setProduct({ ...product, topSeller: (event.target.value).replace(/,/g, '.') })}
                        />
                    </Form.Group>
                    <Form.Group controlId="uri">
                        <Form.Label>uri</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={'Product uri'}
                            value={product.uri}
                            onChange={(event) => setProduct({ ...product, uri: (event.target.value) })}
                        />
                    </Form.Group>
                    <Form.Group controlId="sortValue">
                        <Form.Label>sortValue (lower comes first)</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={'sortValue'}
                            value={product.sortValue}
                            onChange={(event) => setProduct({ ...product, sortValue: (event.target.value) })}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="bg-secondary" onClick={handleCloseCreateProductModal}>
                        {t('global.close')}
                    </Button>
                    <Button className="bg-primary" onClick={() => { doCreateProduct(); handleCloseCreateProductModal(); }}>
                        {t('global.save')}
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* edit product modal */}
            <Modal show={showEditProductModal} onHide={handleCloseEditProductModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('admin.products.editproduct')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="formProductName">
                        <Form.Label>{t('admin.products.productname')}</Form.Label>
                        <Form.Control
                            type="string"
                            placeholder={t('admin.products.productname')}
                            value={product.productName}
                            onChange={(event) => setProduct({ ...product, productName: event.target.value })}
                        />
                    </Form.Group>
                    <Form.Group controlId="formProductCategory">
                        <Form.Label>{t('admin.products.category')}</Form.Label>
                        <Form.Control as="select" onClick={(event) => setProduct({ ...product, categoryName: event.target.value })}>
                            <option></option>
                            {database.categories.map(category => {
                                return <option key={category.categoryId} value={category.categoryName}>{category.categoryName}</option>
                            })}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="formReferencePrice">
                        <Form.Label>{t('admin.products.referenceprice')}</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder={t('admin.products.referenceprice')}
                            value={product.referencePrice}
                            step="any"
                            onChange={(event) => setProduct({ ...product, referencePrice: (event.target.value).replace(/,/g, '.') })}
                        />
                    </Form.Group>
                    <Form.Group controlId="formSalePrice">
                        <Form.Label>{t('admin.products.saleprice')}</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder={t('admin.products.saleprice')}
                            value={product.salePrice}
                            step="any"
                            onChange={(event) => setProduct({ ...product, salePrice: (event.target.value).replace(/,/g, '.') })}
                        />
                    </Form.Group>
                    <Form.Group controlId="formTopSeller">
                        <Form.Label>{t('admin.products.topseller')}</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={t('admin.products.topseller')}
                            value={product.topSeller}
                            onChange={(event) => setProduct({ ...product, topSeller: (event.target.value).replace(/,/g, '.') })}
                        />
                    </Form.Group>
                    <Form.Group controlId="uri">
                        <Form.Label>uri</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={'product uri'}
                            value={product.uri}
                            onChange={(event) => setProduct({ ...product, uri: (event.target.value) })}
                        />
                    </Form.Group>
                    <Form.Group controlId="sortValue">
                        <Form.Label>sortValue (lower comes first)</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={'sortValue'}
                            value={product.sortValue}
                            onChange={(event) => setProduct({ ...product, sortValue: (event.target.value) })}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="bg-secondary" onClick={handleCloseEditProductModal}>
                        {t('global.close')}
                    </Button>
                    <Button className="bg-primary" onClick={() => { doUpdateProduct(productIdToBeUpdated); handleCloseEditProductModal(); }}>
                        {t('global.save')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Container className="">
                <Row>
                    <Col xs={12} className="my-5">
                        <Card>
                            <Card.Body>
                                <Card.Title>{t('admin.products.products')}</Card.Title>
                                {!loading && (
                                    <Card.Text>
                                        <DataTable dataTable={productsTable} />
                                    </Card.Text>
                                )}
                                {loading && <Spinner animation="grow" />}
                            </Card.Body>
                            <Card.Footer>
                                <Button variant="primary" onClick={handleShowCreateProductModal}>
                                    {t('admin.products.createproduct')}
                                </Button>
                            </Card.Footer>
                        </Card>
                    </Col>
                    <Col xs={12} className="my-5">
                        <Card>
                            <Card.Body>
                                <Card.Title>{t('admin.products.categories')}</Card.Title>
                                {!loading && (
                                    <Card.Text>
                                        <DataTable dataTable={categoriesTable} />
                                    </Card.Text>
                                )}
                                {loading && <Spinner animation="grow" />}
                            </Card.Body>
                            <Card.Footer>
                                <Button variant="primary" onClick={handleShowCategoriesModal}>
                                    {t('admin.products.createcategory')}
                                </Button>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

const condition = (authUser) => authUser && !!authUser.isAdmin == true;

const ProductsPage = compose(withAuthorization(condition), withDatabase)(Products);

export default ProductsPage;
