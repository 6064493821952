import React, {useState, useEffect, useContext, useRef} from "react";
import {withAuthorization, withAuthentication} from "../Session";
import {compose} from "recompose";
import {Card, Row, Col, Spinner, Table, Container, Button} from "react-bootstrap";
import {withDatabase} from "../Database";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import {DataTable} from "../Common/Datatable";
import LocalizedTime from "../Common/LocalizedTime";
import {LocalizedPrice} from "../Common/LocalizedPrice";
import {LinkContainer} from 'react-router-bootstrap';
import * as ROUTES from "../../constants/routes";

const MyUsers = ({firebase, database}) => {
    const [users, setUsers] = useState(database.users);
    const [offers, setOffers] = useState(database.offers);
    const [sellRequests, setSellRequests] = useState(database.sellRequests);
    const [loading, setLoading] = useState(false);
    const {t, i18n} = useTranslation('common');
    const refs = useRef([]);
    const [myFavorites, setMyFavorites] = useState([]);

    const [usersTable, setUsersTable] = useState({
        columns: [
            {
                label: t('admin.users.company'),
                field: 'company',
                width: 150,
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Company',
                },
            },
            {
                label: t('admin.users.email'),
                field: 'email',
                width: 150,
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Company',
                },
            },
            {
                label: t('admin.products.action'),
                field: 'action',
                sort: 'disabled'
            }
        ],
        rows: []
    });

    const handleAddToFavorite = (uid) => {
        firebase.doUpdateUser(firebase.auth.currentUser.uid, {[`myFavorites.${uid}`]: 1});
    }
    const handleRemoveFromFavorite = (uid) => {
        firebase.doUpdateUser(firebase.auth.currentUser.uid, {[`myFavorites.${uid}`]: 0});
    }

    useEffect(() => {
        let tempUsersTable = {...usersTable};
        while (tempUsersTable.rows.length)
            tempUsersTable.rows.pop();
        database.users.map(user => {
            tempUsersTable.rows.push({
                company: user.company ?? '',
                email: user.email ?? '',
                action:
                    <>
                        {database.myFavorites[user.uid] !== 1 && (
                            <Button onClick={() => handleAddToFavorite(user.uid)} title={t('admin.addfavorite')}
                                    variant={"danger"}>
                                <i className={"fas fa-heart"}></i>
                            </Button>
                        )}
                        {database.myFavorites[user.uid] === 1 && (
                            <Button onClick={() => handleRemoveFromFavorite(user.uid)}
                                    title={t('admin.removefavorite')}>
                                <i className={"fas fa-times-circle"}></i>
                            </Button>
                        )}
                    </>
            })
            const isFavorite = database.myFavorites[user.uid] && database.myFavorites[user.uid] === 1;
            if (isFavorite) {
                setMyFavorites(prevState => (
                    [...prevState, _.find(database.users, {'uid': user.uid})]
                ))
            }

        });
        setUsersTable(tempUsersTable);
    }, [database.users]);

    const scrollTo = (pos) => {
        window.scrollTo({
            top: pos,
            behavior: "smooth"
        });
    }

    return (
        <Container className="">
            <Row>
                <Col xs={12} className="my-5">
                    <h1 className={"mb-2"}>{t('navigation.myusers')}</h1>
                </Col>
                <Col xs={12} lg={3}>
                    {_.map(myFavorites, (favorite, key) => {
                        return (
                            <div className={"d-block mb-2 show-cursor customerAnchor"} onClick={() => scrollTo((refs.current[key])["offsetTop"])}>{favorite.company}</div>
                        )
                    })}
                </Col>
                <Col xs={12} lg={9}>
                    {_.map(myFavorites, (favorite, key) => {
                        return (
                            <div className={"mb-5"}>
                                <h5 className={""} id={`${key}`} ref={(element) => {refs.current[key] = element}}>{favorite.company}</h5>
                                {
                                    _.map(_.filter(database.offers, {'uid': favorite.uid}), (filteredOffer, index) => {
                                        return <LinkContainer to={`${ROUTES.ADMIN_OFFERS}/${filteredOffer.offerId}`}>
                                            <Row className={"show-cursor my-3 shadow-sm py-3 mx-1 favoritesRow"} key={index} >
                                            <Col xs={3}>
                                                <h6>{t('admin.offer')}{' '} {filteredOffer.offer?.easybillId ?? ''}</h6>
                                            </Col>
                                            <Col xs={3} className={"numeric"}>
                                                <LocalizedTime seconds={filteredOffer.createdAt.seconds * 1000} />
                                            </Col>
                                            <Col xs={3}>
                                                {filteredOffer.status}
                                            </Col>
                                            <Col xs={3}>
                                                <LocalizedPrice priceString={(filteredOffer.overallCartPrice).toString()}/>
                                            </Col>
                                        </Row>
                                        </LinkContainer>
                                    })
                                }

                            </div>
                        )
                    })}
                </Col>


            </Row>
        </Container>
    );
};

const condition = (authUser) => authUser && !!authUser.isAdmin == true;

const MyUsersPage = compose(withAuthorization(condition), withDatabase)(MyUsers);

export default MyUsersPage;
