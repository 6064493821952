import React, {useState, useEffect, useContext, createRef} from "react";
import {Card, Row, Col, Spinner, Container, Button, Modal, Form, CardDeck, Toast} from "react-bootstrap";
import {useTranslation} from "react-i18next";

const Privacy = ({firebase, database}) => {

    const {t, i18n} = useTranslation('common');
    return <>
        <Container className="my-5">
            <h1 className="display-4">{t('pages.privacy.pagetitle')}</h1>
            {[...Array(20)].map((x, i) => {
                return (<>
                    <h5>{t(`pages.privacy.${i+1}.title`)}</h5>
                    <p>{t(`pages.privacy.${i+1}.content`)}
                        {i+1 === 2 && (
                            <p>
                                Softwarebuddies GmbH<br/>
                                Leopoldstr. 2-8<br/>
                                32051 Herford<br/>
                                {t('pages.agb.germany')}
                            </p>
                        )}
                    </p>
                    {i+1 === 1 && [...Array(11)].map((sub, index) => {
                        return (
                            <>
                                <h5 className={"pl-3"}>{t(`pages.privacy.${i+1}.subpoints.${index+1}.title`)}</h5>
                                <p className={"pl-3"}>{t(`pages.privacy.${i+1}.subpoints.${index+1}.content`)}</p>
                            </>
                        )
                    })}
                    {i+1 === 8 && [...Array(9)].map((sub, index) => {
                        return (
                            <>
                                <h5 className={"pl-3"}>{t(`pages.privacy.${i+1}.subpoints.${index+1}.title`)}</h5>
                                <p className={"pl-3"}>{t(`pages.privacy.${i+1}.subpoints.${index+1}.content`)}</p>
                            </>
                        )
                    })}
                </>)
            })
            }
        </Container>
    </>
}

export default Privacy;