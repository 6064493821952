import React from "react";

const LocalizedTime = ({ seconds, onlyDays, reduceToDay }) => {
    if (onlyDays) {
        return (new Date(seconds).toISOString()).split('T')[0];
    }
    if (reduceToDay) {
        const dayZeroed = getDayWithZeroSeconds(seconds);
        return dayZeroed.toISOString().split('T')[0];

    }
    return new Date(seconds).toISOString().replace(/T/, ' ').replace(/\..+/, '')
}

export const getDayWithZeroSeconds = (seconds) => {
    const actual = new Date(seconds);
    const reduced = new Date(`${actual.getFullYear()}-${actual.getMonth() + 1}-${actual.getDate()}`);
    return reduced;
}

export default LocalizedTime;