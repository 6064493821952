import React, {useState, useEffect, useContext, createRef} from "react";
import {Card, Row, Col, Spinner, Container, Button, Modal, Form, CardDeck, Toast} from "react-bootstrap";
import {useTranslation} from "react-i18next";

const Cancellation = ({firebase, database}) => {

    const {t, i18n} = useTranslation('common');
    return <>
        <Container className="my-5">
            <h1 className="display-4">{t('pages.cancellation.pagetitle')}</h1>
            <p>{t('pages.cancellation.intro')}</p>
        </Container>
    </>
}

export default Cancellation;