import React, { useState, useEffect, useContext, createRef } from "react";
import { withAuthorization, withAuthentication } from "../Session";
import { compose } from "recompose";
import { useParams } from 'react-router';
import _ from "lodash";

import { Card, Row, Col, Spinner, Container, Button, Modal, Form, CardDeck, Toast } from "react-bootstrap";
import { withDatabase } from "../Database";
import { useTranslation } from "react-i18next";
import * as Icons from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataTable } from "../Common/Datatable";
import { LocalizedPrice } from "../Common/LocalizedPrice";
import { CartContext } from "../App";
import {useHistory} from "react-router-dom";
import * as ROUTES from '../../constants/routes';

const Landing = ({ firebase, database }) => {
    const [categories, setCategories] = useState(database.categories);
    const [products, setProducts] = useState(database.products);
    const [loading, setLoading] = useState(false);
    const { t, i18n } = useTranslation('common');
    const [activeFilterString, setActiveFilterString] = useState('');
    const [activeQuantity, setActiveQuantity] = useState('');
    const [showToast, setShowToast] = useState(false);
    const [saleProducts, setSaleProducts] = useState([]);
    const [topseller, setTopseller] = useState([]);
    const [alreadyAdded, setAlreadyAdded] = useState(false);
    const { id } = useParams();
    const history = useHistory();


    const cartContext = useContext(CartContext);
    const toggleToast = () => setShowToast(!showToast);
    // Tables
    const [categoriesTable, setCategoriesTable] = useState({
        columns: [
            {
                label: 'Name',
                field: 'name',
                width: 150,
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Name',
                },
                sort: 'asc'
            },
            {
                label: 'Icon',
                field: 'icon',
                width: 270,
                sort: 'disabled'
            },
            {
                label: t('admin.products.action'),
                field: 'action',
                sort: 'disabled'
            }
        ],
        rows: []
    });
    const [productsTable, setProductsTable] = useState({
        columns: [
            {
                label: t('admin.products.product'),
                field: 'productName',
                width: 150,
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Product',
                },
            },
            {
                label: t('admin.products.category'),
                field: 'categoryName',
                width: 270,
            },
            {
                label: t('admin.products.referenceprice'),
                field: 'referencePrice',
                attributes: {
                    'className': 'd-none'
                }
            },
            {
                label: t('admin.products.referenceprice'),
                field: 'referencePriceFloat',
                attributes: {
                }
            },
            {
                label: t('admin.products.action'),
                field: 'action',
                sort: 'disabled'
            }
        ],
        rows: []
    });
    const [filteredProductsTable, setFilteredProductsTable] = useState({
        columns: [
            {
                label: t('admin.products.product'),
                field: 'productName',
                width: 150,
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Product',
                },
            },
            {
                label: t('admin.products.category'),
                field: 'categoryName',
                width: 270,
            },
            {
                label: t('admin.products.referenceprice'),
                field: 'referencePrice',
                attributes: {
                    'className': 'd-none'
                }
            },
            {
                label: t('admin.products.referenceprice'),
                field: 'referencePriceFloat',
                attributes: {
                }
            },
            {
                label: t('admin.products.action'),
                field: 'action',
                sort: 'disabled'
            }
        ],
        rows: []
    });

    const [saleProductsTable, setSaleProductsTable] = useState({
        columns: [
            {
                label: t('admin.products.product'),
                field: 'productName',
                width: 150,
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Product',
                },
            },
            {
                label: t('admin.products.category'),
                field: 'categoryName',
                width: 270,
            },
            {
                label: t('admin.products.referenceprice'),
                field: 'referencePrice',
                attributes: {
                    'className': 'd-none'
                }
            },
            {
                label: t('admin.products.referenceprice'),
                field: 'referencePriceFloat',
                attributes: {
                }
            },
            {
                label: t('admin.products.saleprice'),
                field: 'salePrice',
                attributes: {
                    'className': 'd-none'
                }
            },
            {
                label: t('admin.products.saleprice'),
                field: 'salePriceFloat',
                attributes: {
                }
            },
            {
                label: t('admin.products.action'),
                field: 'action',
                sort: 'disabled'
            }
        ],
        rows: []
    });
    const [topsellerTable, setTopsellerTable] = useState({
        columns: [
            {
                label: t('admin.products.product'),
                field: 'productName',
                width: 150,
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Product',
                },
            },
            {
                label: t('admin.products.category'),
                field: 'categoryName',
                width: 270,
            },
            {
                label: t('admin.products.referenceprice'),
                field: 'referencePrice',
                attributes: {
                    'className': 'd-none'
                }
            },
            {
                label: t('admin.products.referenceprice'),
                field: 'referencePriceFloat',
                attributes: {
                }
            },

            {
                label: t('admin.products.action'),
                field: 'action',
                sort: 'disabled'
            }
        ],
        rows: []
    });

    const toggleActiveBoxClass = (target, categoryName) => {
        target.classList.toggle("activeBox");
        if (!target.classList.contains("activeBox")) {
            setActiveFilterString("");
            let tempTable = { ...productsTable };
            tempTable.rows.sort(({ createdAt: previous }, { createdAt: current }) => previous - current);
            setProductsTable(tempTable);
            return;
        }
        let tempFilterTable = { ...filteredProductsTable };
        let filteredRows = productsTable.rows.filter(row => {
            return row.categoryName === categoryName;
        });
        tempFilterTable.rows = filteredRows.sort(({ createdAt: previous }, { createdAt: current }) => previous - current);
        setFilteredProductsTable(tempFilterTable);
    }

    const handleAddToCart = (productName, target) => {
        const productQuantity = parseInt(target.parentElement.firstChild.value);
        if (isNaN(productQuantity) || productQuantity < 1 || productQuantity > 9999)
            return;

        let price = "";
        if (target.getAttribute('data-saleprice') !== '')
            price = target.getAttribute('data-saleprice');
        else {
            price = target.getAttribute('data-referenceprice');
        }

        target.parentElement.firstChild.value = '';
        cartContext.addToCart({ productName, productQuantity, price });
        setShowToast(true);
    }

    const handleActiveQuantity = (value) => {

        setActiveQuantity(value);
    }

    useEffect(() => {
        /* categories fetching */
        let tempTable = { ...categoriesTable };
        while (tempTable.rows.length)
            tempTable.rows.pop();
        database.categories.map(category => {
            tempTable.rows.push({
                name: category.categoryName,
                icon: <FontAwesomeIcon icon={[category.iconPrefix, category.iconName]}></FontAwesomeIcon>,
            })
        });
        setCategoriesTable(tempTable);

        /* products fetching */
        let tempProductsTable = { ...productsTable };
        let tempSaleProductsTable = { ...saleProductsTable };
        let tempTopsellerTable = { ...topsellerTable };
        while (tempProductsTable.rows.length)
            tempProductsTable.rows.pop();
        while (tempSaleProductsTable.rows.length)
            tempSaleProductsTable.rows.pop();
        while (tempTopsellerTable.rows.length)
            tempTopsellerTable.rows.pop();
        // database.products.sort(({ createdAt: previous }, { createdAt: current }) => current - previous).map(product => {
        const tempProducts = _.orderBy(database.products, ["sortValue"], ["asc"]);
        tempProducts.map(product => {

            tempProductsTable.rows.push({
                productName: product.productName,
                categoryName: product.categoryName,
                // referencePrice: <LocalizedPrice priceString={product.referencePrice}></LocalizedPrice>,
                referencePrice: <LocalizedPrice priceString={(product.referencePrice)}></LocalizedPrice>,
                referencePriceFloat: parseFloat(product.referencePrice),
                salePrice: product.salePrice,
                createdAt: (product.createdAt.seconds * 1000),
                action:
                    <>
                        <Form.Group className="d-flex flex-direction-row justify-content-start align-items-center fixedHeight" key={product.productName}>
                            <Form.Control
                                type="number"
                                placeholder={t('landing.quantity')}
                                style={{ width: 200 }}
                                onChange={(event) => { handleActiveQuantity(event.target.value); }}
                                className="productQuantity"
                            />
                            <Button size="sm" className="fixedHeight" title={t('landing.addtocart')} onClick={(event) => { handleAddToCart(product.productName, event.currentTarget) }} data-referenceprice={product.referencePrice} data-saleprice={product.salePrice}>
                                <FontAwesomeIcon icon={Icons.faShoppingCart} />
                            </Button>

                        </Form.Group>
                    </>
            })
            if (product.salePrice !== "") {
                tempSaleProductsTable.rows.push({
                    productName: product.productName,
                    categoryName: product.categoryName,
                    // referencePrice: <LocalizedPrice priceString={product.referencePrice}></LocalizedPrice>,
                    referencePrice: <del><LocalizedPrice priceString={(product.referencePrice)}></LocalizedPrice></del>,
                    referencePriceFloat: parseFloat(product.referencePrice),
                    salePrice: <LocalizedPrice priceString={(product.salePrice)}></LocalizedPrice>,
                    salePriceFloat: parseFloat(product.salePrice),
                    createdAt: (product.createdAt.seconds * 1000),
                    action:
                        <>
                            <Form.Group className="d-flex flex-direction-row justify-content-start align-items-center fixedHeight" key={product.productName}>
                                <Form.Control
                                    type="number"
                                    placeholder={t('landing.quantity')}
                                    style={{ width: 200 }}
                                    onChange={(event) => { handleActiveQuantity(event.target.value); }}
                                    className="productQuantity"
                                />
                                <Button size="sm" className="fixedHeight" title={t('landing.addtocart')} onClick={(event) => { handleAddToCart(product.productName, event.currentTarget) }} data-referenceprice={product.referencePrice} data-saleprice={product.salePrice}>
                                    <FontAwesomeIcon icon={Icons.faShoppingCart} />
                                </Button>

                            </Form.Group>
                        </>
                })
            }
            if (product.topSeller === "1") {
                tempTopsellerTable.rows.push({
                    productName: product.productName,
                    categoryName: product.categoryName,
                    // referencePrice: <LocalizedPrice priceString={product.referencePrice}></LocalizedPrice>,
                    referencePrice: <LocalizedPrice priceString={(product.referencePrice)}></LocalizedPrice>,
                    referencePriceFloat: parseFloat(product.referencePrice),
                    salePrice: <LocalizedPrice priceString={(product.salePrice)}></LocalizedPrice>,
                    salePriceFloat: parseFloat(product.salePrice),
                    createdAt: (product.createdAt.seconds * 1000),
                    action:
                        <>
                            <Form.Group className="d-flex flex-direction-row justify-content-start align-items-center fixedHeight" key={product.productName}>
                                <Form.Control
                                    type="number"
                                    placeholder={t('landing.quantity')}
                                    style={{ width: 200 }}
                                    onChange={(event) => { handleActiveQuantity(event.target.value); }}
                                    className="productQuantity"
                                />
                                <Button size="sm" className="fixedHeight" title={t('landing.addtocart')} onClick={(event) => { handleAddToCart(product.productName, event.currentTarget) }} data-referenceprice={product.referencePrice} data-saleprice={product.salePrice}>
                                    <FontAwesomeIcon icon={Icons.faShoppingCart} />
                                </Button>

                            </Form.Group>
                        </>
                })
            }


        })
        setProductsTable(tempProductsTable);
        setSaleProductsTable(tempSaleProductsTable);
        setTopsellerTable(tempTopsellerTable);
    }, [database.categories, database.products])

    /**
     * This handles adding products directly to the cart via the url
     */
    useEffect(() => {
        if(id && database.products.length !== 0){
            // Convert to readable form.
            const productUri = atob(id).trim();
            const product = _.filter(database.products, {'uri': productUri});
            if(product.length !== 0){
                let price = 1;
                if (product[0].salePrice.length !== 0){
                    price = product[0].salePrice;
                } else if (product[0].referencePrice !== 0) {
                    price = product[0].referencePrice;
                }
                cartContext.addToCart({ productName: product[0].productName, productQuantity: 1, price: price});
                history.push(ROUTES.LANDING);
                cartContext.setShowCart(true);
            }
            else {
                history.push(ROUTES.LANDING);
            }
        }
    }, [database.products])
    return <>

        <Container className="my-5">
            <h1 className="display-4">{t('landing.headline')}</h1>
            <p className="lead">
                {t('landing.leadtext')}
            </p>
            <hr></hr>
            {saleProductsTable.rows.length !== 0 && (
                <>
                    <Row className="my-3">
                        <Col xs={12}>
                            <h1 className="h2 mb-3 mb-md-5">{t('landing.currentsale')}</h1>
                        </Col>
                        <Col xs={12}>
                            <DataTable dataTable={saleProductsTable} className="saleTable" entries={5}/>
                        </Col>

                    </Row>

                </>
            )}
            {topsellerTable.rows.length !== 0 && (
                <>
                    <Row className="my-3">
                        <Col xs={12}>
                            <h1 className="h2 mb-3 mb-md-5">{t('landing.topseller')}</h1>
                        </Col>
                        <Col xs={12}>
                            <DataTable dataTable={topsellerTable} className="overviewTable" entries={5} />
                        </Col>

                    </Row>

                </>
            )}
            <Row className="my-5">
                <Col xs={12} md={3} className="pr-md-4">
                    <h1 className="h3 mb-3 mb-md-5">{t('landing.filtercategory')}</h1>
                    {database.categories.map((category, index) => {
                        return <Col
                            key={index}
                            xs={12}
                            className={`categoryBox shadow ${index === 0 ? "mt-0 mb-4 " : "my-4  "}px-3 d-flex align-items-center justify-content-start flex-row ${category.categoryName === activeFilterString ? "activeBox" : ""}`}
                            onClick={(event) => { setActiveFilterString(category.categoryName); toggleActiveBoxClass(event.currentTarget, category.categoryName) }}>
                            {category.categoryId === "0GddCmRBkTTjSGOjhIep" && (
                                <img src="/icons/package-variant-closed.svg" className="img shadow p-2 landingIcons" />
                            )}
                            {category.categoryId === "8dB5Vte6p79N3rNAE9A4" && (
                                <img src="/icons/server.svg" className="img shadow p-2 landingIcons" />
                            )}
                            {category.categoryId === "Ej9Id64drq180YFAXz1u" && (
                                <img src="/icons/file-cad.svg" className="img shadow p-2 landingIcons" />
                            )}
                            {category.categoryId === "MWuy6YcT4uSErIitVV6f" && (
                                <img src="/icons/remote-desktop.svg" className="img shadow p-2 landingIcons" />
                            )}
                            {category.categoryId === "Xsh6akoi8pOOlGxrg4Uc" && (
                                <img src="/icons/draw.svg" className="img shadow p-2 landingIcons" />
                            )}
                            {category.categoryId === "iSJZDpqOMxtylBjbr1cX" && (
                                <img src="/icons/microsoft-windows.svg" className="img shadow p-2 landingIcons" />
                            )}
                            {category.categoryId === "QDlXd2VpzUjdl6iKi4Mu" && (
                                <img src="/icons/microsoft-office.svg" className="img shadow p-2 landingIcons" />
                            )}
                            {category.categoryName}

                        </Col>
                    })}
                </Col>
                <Col xs={12} md={9} className="pl-md-4">
                    {!activeFilterString && (
                        <DataTable dataTable={productsTable} className="overviewTable" entries={10}/>
                    )}
                    {activeFilterString && (
                        <DataTable dataTable={filteredProductsTable} className="overviewTable" entries={10} />
                    )}
                </Col>
            </Row>

            <Toast show={showToast} onClose={() => toggleToast()} className="cartToast" delay={3000} autohide>
                <Toast.Header className="text-secondary">
                    <strong className="mr-auto">{t('cart.cart')}</strong>
                </Toast.Header>
                <Toast.Body>{t('cart.added')}</Toast.Body>
            </Toast>
        </Container>
    </>
}

export default compose(withDatabase)(Landing);