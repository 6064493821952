
import React, { useState, useEffect, useContext} from "react";
import { withAuthentication, AuthUserContext } from '../Session';
import { compose } from "recompose";
import { Card, Row, Col, Spinner, Container, Button, Badge, Accordion } from "react-bootstrap";
import { withDatabase } from "../Database";
import { useTranslation } from "react-i18next";

import { LocalizedPrice } from "../Common/LocalizedPrice";
import LocalizedTime  from "../Common/LocalizedTime";


const INITIAL_FILELOADERS = {
    "offerPdf": false,
    "confirmationPdf": false,
    "invoicePdf": false,
    "licenceFilesPdf": false
};
const MyOrders = ({ database, firebase }) => {
    const { t, i18n } = useTranslation('common');
    const authUser = useContext(AuthUserContext);
    const [fileLoaders, setFileLoaders] = useState(INITIAL_FILELOADERS);
    const [offerList, setOfferList] = useState([]);

    const handleOfferPdf = (fileName, offerId) => {
        setFileLoaders({ ...fileLoaders, offerPdf: true });
        firebase.doFetchFileUrl(fileName, offerId).then(url => {
            setFileLoaders({ ...fileLoaders, offerPdf: false });
            window.open(url, '_blank', 'noopener,noreferrer');
        }).catch(err => {
            console.log(err);
            setFileLoaders({ ...fileLoaders, offerPdf: false });
        })
    }

    const handleConfirmationPdf = (fileName, offerId) => {
        setFileLoaders({ ...fileLoaders, confirmationPdf: true });
        firebase.doFetchFileUrl(fileName, offerId).then(url => {
            setFileLoaders({ ...fileLoaders, confirmationPdf: false });
            window.open(url, '_blank', 'noopener,noreferrer');
        }).catch(err => {
            console.log(err);
            setFileLoaders({ ...fileLoaders, confirmationPdf: false });
        })
    }
    const handleInvoicePdf = (fileName, offerId) => {
        setFileLoaders({ ...fileLoaders, invoicePdf: true });
        firebase.doFetchFileUrl(fileName, offerId).then(url => {
            setFileLoaders({ ...fileLoaders, invoicePdf: false });
            window.open(url, '_blank', 'noopener,noreferrer');
        }).catch(err => {
            console.log(err);
            setFileLoaders({ ...fileLoaders, invoicePdf: false });
        })
    }

    const handleLicenceFilesPdf = (fileName, offerId) => {
        setFileLoaders({ ...fileLoaders, licenceFilesPdf: true });
        firebase.doFetchFileUrl(fileName, offerId).then(url => {
            setFileLoaders({ ...fileLoaders, licenceFilesPdf: false });
            window.open(url, '_blank', 'noopener,noreferrer');
        }).catch(err => {
            console.log(err);
            setFileLoaders({ ...fileLoaders, licenceFilesPdf: false });
        })
    }


    useEffect(() => {
        if (authUser) {
            const actualOffers = database.offers.filter(offer => offer.uid === authUser.uid).sort(({ createdAt: previous }, { createdAt: current }) => current - previous);
            const realOffers = actualOffers.filter(offer => offer.status !== "Pending" && offer.status !== "Available" && offer.status !== "Declined")
            setOfferList(realOffers);
            // Populate offers with files
            localStorage.setItem('myOrders', JSON.stringify(actualOffers));
        }


    }, [database.offers, authUser]);

    useEffect(() => {
        const localMyOffers = JSON.parse(localStorage.getItem('myOrders'));
        if (localMyOffers) {
            const realOffers = localMyOffers.filter(offer => offer.status !== "Pending" && offer.status !== "Available" && offer.status !== "Declined");
            setOfferList(realOffers);
        }
    }, []);


    return <>
        <Container className="my-5">
            <h1 className="h2">{t('navigation.myorders')}</h1>
            <Row>
                {offerList.length === 0 &&
                    <Col xs={12} className="mb-3">
                        <p>{t('myoffers.noorders')}</p>
                    </Col>}
                <Accordion defaultActiveKey="0" className="w-100">

                    {offerList.length !== 0 && offerList.map((offer, index) => {

                        return <Col xs={12} md={12} className="my-3">
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey={`${index}`} className="d-flex flex-direction-row justify-content-between offerButton w-100 p-0">
                                        <div className="text-left">
                                            <h2>{t('myoffers.orderaccording')} {''} {offer.offer && offer.offer.easybillId}</h2>

                                            <span className="small d-block">
                                                {t('myoffers.requestedat')}{' '}
                                                <LocalizedTime seconds={offer.createdAt.seconds * 1000} />
                                            </span>
                                            <span className="small d-block ">
                                                {t('myoffers.orderedat')}{' '}
                                                <LocalizedTime seconds={offer.order.createdAt.seconds * 1000} />
                                            </span>
                                        </div>
                                        <div className="d-flex flex-column text-right">
                                            {offer.status === 'Pending' && <Badge variant="warning">{t('global.status.pending')}</Badge>}
                                            {offer.status === 'Available' && <Badge variant="primary">{t('global.status.available')}</Badge>}
                                            {offer.status === 'Completed' && <Badge variant="success">{t('global.status.completed')}</Badge>}
                                            {offer.status === 'Declined' && <Badge variant="danger">{t('global.status.declined')}</Badge>}
                                            {offer.status === 'Ordered' && <Badge variant="info">{t('global.status.ordered')}</Badge>}
                                            {offer.status === 'Confirmed' && <Badge variant="secondary">{t('global.status.confirmed')}</Badge>}
                                            {offer.status !== "Pending" && offer.status !== "Available" && offer.status !== "Declined" && !offer.paymentStatus && (
                                                <div>
                                                    <small>{t('myoffers.payment.status')}: <i className="fa fa-times-circle text-danger" title={t('myoffers.payment.open')}></i></small>
                                                </div>
                                            )}
                                            {offer.paymentStatus && (
                                                <div>
                                                    <small>{t('myoffers.payment.status')}: <i className="fa fa-check-circle text-success" title={t('myoffers.payment.paid')}></i></small>
                                                </div>
                                            )}
                                        </div>
                                    </Accordion.Toggle>

                                </Card.Header>
                                <Accordion.Collapse eventKey={`${index}`}>
                                    <>
                                        <Card.Body>
                                            <Card.Title>{t('myoffers.overviewyourrequest')}</Card.Title>
                                            <Card.Text>
                                                <Row>
                                                    <Col xs={2} className="text-bold small text-left">{t('cart.thproductQuantity')}</Col>
                                                    <Col xs={6} className="text-bold small">{t('cart.thproductName')}</Col>
                                                    <Col xs={2} className="text-bold small text-right">{t('cart.thsinglePrice')}</Col>
                                                    <Col xs={2} className="text-bold small text-right">{t('cart.thprice')}</Col>
                                                    {offer.cartItems.map(item => {
                                                        return <><Col xs={2} className="text-left">
                                                            {item.productQuantity}</Col>
                                                            <Col xs={6}>{item.productName}</Col>
                                                            <Col className="text-right" xs={2}><LocalizedPrice priceString={(item.price).toString()} /></Col>
                                                            <Col className="text-right" xs={2}><LocalizedPrice priceString={(item.price * item.productQuantity).toString()} /></Col>

                                                        </>
                                                    })}
                                                    <Col xs={2} className="text-bold small"></Col>
                                                    <Col xs={2} className="text-bold small"></Col>
                                                    <Col xs={6} className="text-bold text-right">{t('cart.overall')}:</Col>
                                                    <Col xs={2} className="text-bold text-right"><LocalizedPrice priceString={offer.overallCartPrice.toString()}></LocalizedPrice></Col>
                                                </Row>
                                            </Card.Text>
                                            {offer.status !== "Pending" && (
                                                <Card>
                                                    <Card.Body>
                                                        <Card.Title>
                                                            {t('myoffers.ouroffer')}
                                                        </Card.Title>
                                                        <Card.Text>
                                                            <div>
                                                                {t('myoffers.netprice')}: <span className="font-weight-bold"><LocalizedPrice priceString={(offer.offer.netPrice).toString()} /></span>
                                                            </div>
                                                            <div>
                                                                {t('myoffers.validuntil')}: <span className="font-weight-bold"><LocalizedTime reduceToDay={true} seconds={offer.offer.validUntil.seconds * 1000}></LocalizedTime></span>
                                                            </div>
                                                            <div className="text-primary show-cursor d-inline" onClick={() => handleOfferPdf(offer.offer.fileName, offer.offerId)}>
                                                                {t('myoffers.offerpdf')}{' '}(PDF)
                                                    {fileLoaders["offerPdf"] && <Spinner animation="grow" size="sm" />}
                                                            </div>
                                                        </Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            )}
                                            {offer.status !== "Pending" && offer.status !== "Ordered" && (
                                                <Card>
                                                    <Card.Body>
                                                        <Card.Title>
                                                            {t('myoffers.yourfiles')}
                                                        </Card.Title>
                                                        <Card.Text>
                                                            <span className="d-block">{t('myoffers.confirmation')}: {offer.order.confirmation.easybillId}</span>
                                                            <div className="text-primary show-cursor d-inline" onClick={() => handleConfirmationPdf(offer.order.confirmation.fileName, offer.offerId)}>
                                                                {t('myoffers.confirmationpdf')}{' '}(PDF)
                                                    {fileLoaders["confirmationPdf"] && <Spinner animation="grow" size="sm" />}
                                                            </div>
                                                        </Card.Text>
                                                        <Card.Text>
                                                            {offer.status === "Completed" && (
                                                                <>
                                                                    <span className="d-block">{t('myoffers.invoice')}: {offer.order.invoice.easybillId}</span>
                                                                    <div className="text-primary show-cursor d-inline" onClick={() => handleInvoicePdf(offer.order.invoice.fileName, offer.offerId)}>
                                                                        {t('myoffers.invoicepdf')}{' '}(PDF)
                                                                    {fileLoaders["invoicePdf"] && <Spinner animation="grow" size="sm" />}
                                                                    </div>
                                                                </>
                                                            )}
                                                        </Card.Text>
                                                        <Card.Text className="my-0">
                                                            {offer.status === "Completed" && (
                                                                <div className="text-primary show-cursor d-inline" onClick={() => handleLicenceFilesPdf(offer.order.licences.fileName, offer.offerId)}>
                                                                    {t('myoffers.licencepdf')}{' '}(PDF)
                                                                    {fileLoaders["licenceFilesPdf"] && <Spinner animation="grow" size="sm" />}
                                                                </div>
                                                            )}
                                                        </Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            )}
                                            {offer.status === "Completed" && offer.downloadFiles && offer.downloadFiles.length !== 0 && (
                                                <Card>
                                                    <Card.Body>
                                                        <Card.Title>
                                                            {t('myoffers.yourdownloadfiles')}
                                                        </Card.Title>

                                                        <Card.Text className="my-0">

                                                            {offer.downloadFiles.map((dlfile, index) => {
                                                                if (dlfile.portalUsername && dlfile.portalPassword && dlfile.articleName && dlfile.articleLink) {
                                                                    return (
                                                                        <div className="my-2">
                                                                            {index !== 0 && <hr className="w-100" />}

                                                                            <span className="d-block">Username: {dlfile.portalUsername}</span>
                                                                            <span className="d-block">{t('signup.password')}: {dlfile.portalPassword}</span>
                                                                            <a href={dlfile.articleLink} target="_blank" className="text-primary show-cursor d-inline custom-link">
                                                                                {dlfile.articleName}
                                                                            </a>
                                                                        </div>
                                                                    )
                                                                }
                                                                else return ''
                                                            })}
                                                        </Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            )}

                                        </Card.Body>
                                        <Card.Footer>
                                        </Card.Footer>
                                    </>
                                </Accordion.Collapse>
                            </Card>
                        </Col>
                    })}
                </Accordion>
            </Row>
        </Container>
    </>
}

const MyOrdersPage = compose(withAuthentication, withDatabase)(MyOrders);

export default MyOrdersPage;