import React, {useState, useEffect, useContext} from "react";
import {withAuthorization, withAuthentication} from "../Session";
import {compose} from "recompose";
import {Card, Row, Col, Spinner, Table, Container, Button} from "react-bootstrap";
import {withDatabase} from "../Database";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import {DataTable} from "../Common/Datatable";


const Users = ({firebase, database}) => {
    const [users, setUsers] = useState(database.users);
    const [offers, setOffers] = useState(database.offers);
    const [sellRequests, setSellRequests] = useState(database.sellRequests);
    const [loading, setLoading] = useState(false);
    const {t, i18n} = useTranslation('common');

    const [myFavorites, setMyFavorites] = useState([]);

    const [usersTable, setUsersTable] = useState({
        columns: [
            {
                label: t('admin.users.company'),
                field: 'company',
                width: 150,
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Company',
                },
            },
            {
                label: t('admin.users.email'),
                field: 'email',
                width: 150,
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Company',
                },
            },
            {
                label: t('admin.products.action'),
                field: 'action',
                sort: 'disabled'
            }
        ],
        rows: []
    });

    const handleAddToFavorite = (uid) => {
        firebase.doUpdateUser(firebase.auth.currentUser.uid, {[`myFavorites.${uid}`]: 1});
    }
    const handleRemoveFromFavorite = (uid) => {
        firebase.doUpdateUser(firebase.auth.currentUser.uid, {[`myFavorites.${uid}`]: 0});
    }

    useEffect(() => {
        let tempUsersTable = {...usersTable};
        while (tempUsersTable.rows.length)
            tempUsersTable.rows.pop();
        database.users.map(user => {
            tempUsersTable.rows.push({
                company: user.company ?? '',
                email: user.email ?? '',
                action:
                    <>
                        {database.myFavorites[user.uid] !== 1 && (
                            <Button onClick={() => handleAddToFavorite(user.uid)} title={t('admin.addfavorite')} variant={"danger"}>
                                <i className={"fas fa-heart"}></i>
                            </Button>
                        )}
                        {database.myFavorites[user.uid] === 1 && (
                            <Button onClick={() => handleRemoveFromFavorite(user.uid)} title={t('admin.removefavorite')}>
                                <i className={"fas fa-times-circle"}></i>
                            </Button>
                        )}
                    </>
            })
        });
        setUsersTable(tempUsersTable);
    }, [database.users]);


    return (
        <Container className="">
            <Row>
                <Col xs={12} className="my-5">
                    <h1 className={"mb-2"}>{t('navigation.managecustomers')}</h1>
                    <DataTable dataTable={usersTable} entries={20}/>
                </Col>
            </Row>
        </Container>
    );
};

const condition = (authUser) => authUser && !!authUser.isAdmin == true;

const UsersPage = compose(withAuthorization(condition), withDatabase)(Users);

export default UsersPage;
