import React, { useState, useEffect } from "react";
import { withRouter, useHistory, Link } from "react-router-dom";
import { Form, Button, Spinner, Alert, Container, Row, Col, Nav } from "react-bootstrap";
import { LinkContainer } from 'react-router-bootstrap';

import { withFirebase } from "../Firebase";
import { compose } from "recompose";
import { useTranslation, Trans } from "react-i18next";
import Cookies from 'universal-cookie';


import emailjs, { init } from 'emailjs-com';
import * as ROUTES from "../../constants/routes";
init(process.env.REACT_APP_EMAILJS_USER_ID);


const SignUp = ({ firebase }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [company, setCompany] = useState("");
  const [postal, setPostal] = useState("");
  const [name, setName] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [vat, setVat] = useState("");
  const [checked, setChecked] = useState(false);
  const [valid, setValid] = useState(false);
  const [error, setError] = useState("");
  const [errorCount, setErrorCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [waitingTime, setWaitingTime] = useState(0);
  const history = useHistory();
  const { t, i18n } = useTranslation('common');
  const cookies = new Cookies();

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setPassword(password1);
    firebase
      .doCreateUserWithEmailAndPassword(email, password1, t)
      .then((data) => {
        const uid = data.user.uid;
        firebase.doEnhanceUser(uid, {
          city,
          company,
          country,
          email,
          expoPushToken: "",
          name,
          street,
          zip: postal,
          vat,
          preferedLang: cookies.get('prefered_lang') === 'de' ? 'de' : 'en',
          uid,
          notifications: {
            userActions: {
              welcomeMail: {
                sent: null
              }
            }
          }
        });
        setEmail("");
        setPassword("");
        setPassword1("");
        setPassword2("");
        setError("");
        setChecked(false);
        setLoading(false);
        history.push(ROUTES.LANDING);
      })
      .catch((err) => {
        setErrorCount((errorCount) => errorCount + 1);
        setError(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    setValid(
      email.length !== 0 && password1.length !== 0 && password2.length !== 0 &&
      email.includes("@") && password1 === password2 && checked &&
      name.length > 2 && company.length > 2 && postal.length > 2 && city.length > 2 && street.length > 2 && country.length > 2
    );
    if (errorCount > 2) {
      setDisabled(true);
      setTimeout(() => {
        setDisabled(false);
      }, errorCount * errorCount * 1000);
      setWaitingTime(errorCount * errorCount);
    }
  }, [email, password1, password2, errorCount, checked, name, company, postal, city, street, country]);

  return (
    <div className="fullpage-background">
      <Form onSubmit={handleSubmit} className="mt-5 mb-3" autoComplete="on">
        <Container>
          <Row className="box-border-left shadow p-5 p-sm-3 p-lg-5 bg-light">
            <Col xs={12} md={12} className="mb-3"><h1>{t('signup.signup')}</h1></Col>
            <Col xs={12} md={6}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>{t('signup.email')}</Form.Label>
                <Form.Control
                  type="email"
                  placeholder={t('signup.entermail')}
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  maxLength="100"
                  autoComplete="email"
                />
              </Form.Group>
              <Form.Group controlId="formBasicPassword">
                <Form.Label>{t('signup.password')}</Form.Label>
                <Form.Control
                  type="password"
                  placeholder={t('signup.password')}
                  value={password1}
                  onChange={(event) => setPassword1(event.target.value)}
                  maxLength="256"
                  autoComplete="new-password"
                />
              </Form.Group>
              <Form.Group controlId="formBasicPassword">
                <Form.Label>{t('signup.repeatpassword')}</Form.Label>
                <Form.Control
                  type="password"
                  placeholder={t('signup.password')}
                  value={password2}
                  onChange={(event) => setPassword2(event.target.value)}
                  maxLength="256"
                />
              </Form.Group>
              <Form.Group controlId="formBasicName">
                <Form.Label>{t('signup.name')}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t('signup.name')}
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  maxLength="100"
                  autoComplete="name"
                />
              </Form.Group>
              <Form.Group controlId="formBasicCompany">
                <Form.Label>{t('signup.company')}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t('signup.company')}
                  value={company}
                  onChange={(event) => setCompany(event.target.value)}
                  maxLength="100"
                  autoComplete="organization"
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group controlId="formBasicStreet">
                <Form.Label>{t('signup.street')}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t('signup.street')}
                  value={street}
                  onChange={(event) => setStreet(event.target.value)}
                  maxLength="100"
                  autoComplete="street-address"
                />
              </Form.Group>
              <Form.Group controlId="formBasicPostal">
                <Form.Label>{t('signup.postal')}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t('signup.postal')}
                  value={postal}
                  onChange={(event) => setPostal(event.target.value)}
                  maxLength="100"
                  autoComplete="postal-code"
                />
              </Form.Group>
              <Form.Group controlId="formBasicCity">
                <Form.Label>{t('signup.city')}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t('signup.city')}
                  value={city}
                  onChange={(event) => setCity(event.target.value)}
                  maxLength="100"
                  autoComplete="address-level2"
                />
              </Form.Group>
              <Form.Group controlId="formBasicPostal">
                <Form.Label>{t('signup.country')}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t('signup.country')}
                  value={country}
                  onChange={(event) => setCountry(event.target.value)}
                  maxLength="100"
                  autoComplete="country"
                />
              </Form.Group>
              <Form.Group controlId="formBasicVat">
                <Form.Label>{t('signup.uid')}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t('signup.uid')}
                  value={vat}
                  onChange={(event) => setVat(event.target.value)}
                  maxLength="100"
                />
              </Form.Group>
            </Col>
            <Col md={12}>
              <div className="text-muted small my-3">{t('common:signup:required')}</div>
              <Form.Group controlId="formBasicCheckbox">
                <Form.Check type="checkbox" label={<Trans i18nKey="common:signup:acceptance">
                  By registration you accept the <Link to={ROUTES.PRIVACY} target={"_blank"} id={"signup-privacy"}>data privacy statement</Link> and the <Link to="/terms" target={"_blank"} id={"signup-terms"}>terms</Link>.
                </Trans>} className="text-muted " onChange={(event) => setChecked(event.target.checked)} />
                <Link to={ROUTES.PRIVACY} target={"_blank"}>{t('pages.privacy.pagetitle')}</Link> & {' '}
                <Link to={ROUTES.TERMS} target={"_blank"}>{t('pages.agb.pagetitle')}</Link>
              </Form.Group>
              <Button variant="primary" type="submit" disabled={!valid || disabled}>
                {t('signup.signup')} {loading && <Spinner animation="grow" size="sm" />}
              </Button>

              {error && (
                <Alert variant="danger" className="my-3">
                  {t('signup.error')}
                </Alert>
              )}
            </Col>
          </Row>
        </Container>
      </Form>
    </div>
  );
};

const SignUpPage = compose(withRouter, withFirebase)(SignUp);

export default SignUpPage;
