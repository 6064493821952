import React, { useState, useEffect } from "react";
import emailjs, { init } from 'emailjs-com';

init(process.env.REACT_APP_EMAILJS_USER_ID);

const SendMail = ({ subject, salutation, message, email, signup, isNewUser }) => {
    return;
    if (isNewUser) {

        emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_OFFERNEWUSER, {
            subject: subject,
            salutation: salutation,
            message: message,
            to_mail: email,
            signup: signup
        }, process.env.REACT_APP_EMAILJS_USER_ID);
    }
    else {
        emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_GENERIC_TEMPLATE_EN, {
            subject: subject,
            salutation: salutation,
            message: message,
            to_mail: email,
        }, process.env.REACT_APP_EMAILJS_USER_ID);
    }
}

export default SendMail;