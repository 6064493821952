import React from "react";
import ReactDOM from "react-dom";

import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import Cookies from 'universal-cookie';

import * as serviceWorker from "./serviceWorker";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "mdbreact/dist/css/mdb.css";
import "react-datepicker/dist/react-datepicker.css";
import "./styles/App.scss";



import Firebase, { FirebaseContext } from "./components/Firebase";
import App from "./components/App";

import common_de from "./locales/de/common.json";
import common_en from "./locales/en/common.json";


const cookies = new Cookies();

i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: cookies.get('prefered_lang') === 'de' ? 'de' : 'en',                              // language to use
  resources: {
    en: {
      common: common_en               // 'common' is our custom namespace
    },
    de: {
      common: common_de
    },
  },
});

ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </FirebaseContext.Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
